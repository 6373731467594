import app from "firebase/app"
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

app.initializeApp(config)
export const firebase = app
export const db = app.firestore()
export const storageRef = app.storage().ref()
export const groupchatRef = db.collection(process.env.VUE_APP_FIREBASE_GROUP_CHAT)
export const messagesRef = db.collection(process.env.VUE_APP_FIREBASE_MESSAGES)
export const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp()
export const deleteDbField = firebase.firestore.FieldValue.delete()
export const unreadNotificationRef = db.collection(process.env.VUE_APP_FIREBASE_UNREAD_NOTIFICATION)
export const likeNotificationRef = db.collection(process.env.VUE_APP_FIREBASE_LIKE_NOTIFICATION)
export const commentRef = db.collection(process.env.VUE_APP_FIREBASE_COMMENT_NOTIFICATION)
