var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"0px -6px"}},[_c('div',{staticClass:"d-flex flex-column me-xl-5"},[_c('div',{staticClass:"my-xl-5 bg-body-primary button-border-radius-12"},[_c('ul',{staticClass:"tabs list-unstyled d-flex mb-0 flex-xl-column p-xl-6 my-xl-2 button-border-radius-12 mx-h-26"},[_c('div',{staticClass:"d-none d-xl-flex fs-17 fwb"},[_c('i',{staticClass:"fal fa-user-friends fs-25 me-2 mb-2"}),_vm._v(" "+_vm._s(_vm.$t("groups.member_list.title"))+" ")]),_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,staticClass:"flex-1 border-light flex-xl-none "},[_c('router-link',{staticClass:"flex-center h-100 py-3 justify-content-xl-start hover-bg-xl-none",class:{
              'text-white transition-fast fw-xl-b': _vm.$route.name
                ? _vm.$route.name.includes(tab.routerName)
                : false,
              'hover-o-75 ': _vm.$route.name
                ? !_vm.$route.name.includes(tab.routerName) && !tab.disable
                : true,
              'cursor-default': tab.disable,
            },attrs:{"to":tab.disable ? '' : tab.routerLink}},[(_vm.$route.name && _vm.$route.name.includes(tab.routerName))?_c('div',{staticClass:"text-xl-dark"},[_vm._v(" "+_vm._s(tab.displayName)+" ")]):_c('div',{class:{
                'text-secondary': tab.disable,
                'text-xl-primary ': !tab.disable,
              }},[_vm._v(" "+_vm._s(tab.displayName)+" ")])])],1)})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }