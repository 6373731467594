














import { Component, Vue } from 'vue-property-decorator'
import HeaderCompact from '@/components/HeaderCompact.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import HeaderBeforeLogin from "@/components/HeaderBeforeLogin.vue"
import NavMenu from '@/components/NavMenu.vue'
import FooterHome from '@/components/FooterHome.vue'

@Component({
  components: {
    HeaderCompact,
    NavMenuBar,
    FooterMobile,
    NavMenu,
    HeaderBeforeLogin,
    FooterHome
  }
})
export default class LayoutHelpCenter extends Vue {}
