







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ImageItem extends Vue {
  @Prop() imageSrc!: string
  @Prop() className!: string
  @Prop() imageDefault!: string
  // public imageDefault = require('@/assets/images/image-default.png')
  public src: string = this.imageSrc || ''

  // @Watch("imageSrc")
  // updateSrcByImageSrc() {
  //   this.src = this.imageSrc || ''
  // }

  handleOnError() {
    this.src = this.imageDefault
  }
}
