import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import {
  EFolderType,
  FileGetParams,
  DeleteFileParams,
  FileToFolderParams,
  FileHistoryGetParams,
  UpdateInformationParams,
  RevertFileHistoryParams,
  MoveToUnclsFrom
} from '@/models'
class GroupSharedFolderService {

  getAllFolderOfSharedFolder(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/folder/get-all-folder/1`)
  }

  getFileNotDelete(params: FileGetParams) {
    // return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/share-folder?unreadOnly=${params.unread_only}&orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
    return axiosApiInstance.get(`${Const.api_url}groups/${params.group_id}/share-folder?unreadOnly=${params.unread_only}&orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getFileUnclassified(params: FileGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/share-folder-unclassified-file?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getFileDeleted(params: FileGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/share-folder-file-in-bin?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getAttachFiles(params: FileGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/share-folder-attach-file?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getFileByFolder(params: FileGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/share-folder-file-by-folder/${params.folder_id}?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getFileById(groupId: string, fileId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/share-folder-file/${fileId}`)
  }

  isFileFavorite(groupId: string, fileId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/is-favorite-file/${fileId}`)
  }

  getFileHistory(params: FileHistoryGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/share-folder-history-file/${params.file_id}?limit=${params.limit}&page=${params.page}`)
  }

  getSharedFolderFolderItemCount(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/count-share-folder`)
  }

  addNewFileFolder(groupId: string, folderName: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/folder/create-new-folder`, { type: EFolderType.SHARED_FOLDER, folder_name: folderName })
  }

  createNewFile(params: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.get('groupId')}/share-folder-upload-file`, params)
  }

  overwriteFile(params: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.get('groupId')}/overwrite-file/${params.get('fileId')}`, params)
  }

  updateInformation(params: UpdateInformationParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/update-info-file/${params.file_id}`, params)
  }

  moveFilesToUnclassified(groupId: string, fileIds: number[], from: MoveToUnclsFrom) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/move-file-to-unclassified`, { file_ids: fileIds, from: from })
  }

  moveFilesToFolder(params: FileToFolderParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/move-file-to-other-folder`, params)
  }

  deleteFileOrMoveFileToBin(params: DeleteFileParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/delete-file`, params)
  }

  emptySharedFolderBin(groupId: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/empty-share-folder-bin`)
  }

  markFileAsFavorite(groupId: string, fileId: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/mark-favorite-file/${fileId}`)
  }

  markFileAsRead(groupId: string, fileId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/mark-read-file/${fileId}`)
  }

  revertFileHistory(params: RevertFileHistoryParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/revert-file/${params.file_id}/from-history/${params.file_history_id}`)
  }

  deleteHistory(groupId: string, historyId: number, fileId: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/delete-file-history/${historyId}`, { file_id: fileId })
  }
}

export default new GroupSharedFolderService
