var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('nav',{staticClass:"nav-menu position-xl-initial z-1040 bg-white active bg-header-primary",class:_vm.noneFixed
        ? ''
        : 'start-0 end-0 bottom-0 border-top border-top-xl-none border-bottom-none border-bottom-xl position-fixed',staticStyle:{"border-color":"#b7bfc6"},on:{"click":_vm.handleShowPickMenu}},[_c('div',{staticClass:"d-block ps-xl-3 pe-xl-10"},[_c('div',{staticClass:"d-flex justify-content-xl-between align-items-center"},[_c('ul',{staticClass:"list-unstyled d-flex justify-content-between justify-content-xl-start mb-0 w-100 w-xl-auto"},[_c('li',{staticClass:"flex-1 flex-xl-none px-xl-7 hover-linear-gradient"},[_c('router-link',{staticClass:"flex-center flex-column flex-xl-row text-light h--17 h-xl--14",attrs:{"to":"/"}},[_c('i',{staticClass:"fs-14 mb-2 mb-xl-0 me-xl-3",class:_vm.$route.name === 'home'
                    ? 'fas fa-home-alt'
                    : 'fas fa-home-alt',style:(_vm.iconColor)}),_c('span',{staticClass:"h--6 fw-xl-b fs-8 fs-xl-12 h-xl-auto text-white",style:(_vm.titleColor)},[_vm._v(_vm._s(_vm.$t("nav_menu.home")))])])],1),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkLogin),expression:"checkLogin"}],staticClass:"flex-1 flex-xl-none px-xl-7 hover-linear-gradient"},[_c('div',{staticClass:"dropdown",staticStyle:{"position":"initial"}},[_c('div',{staticClass:"btn-pick-up flex-center flex-column flex-xl-row text-light h--17 h-xl--14 cursor-pointer",attrs:{"type":"button","data-bs-toggle":"dropdown","aria-expanded":"false","role":"button"}},[_c('i',{staticClass:"fs-14 mb-2 mb-xl-0 me-xl-3 fas fa-users",style:(_vm.iconColor)}),_c('span',{staticClass:"fw-xl-b fs-8 fs-xl-12 text-pre h--6 h-xl-auto text-white",style:(_vm.titleColor)},[_vm._v(" "+_vm._s(_vm.$t("nav_menu.group"))+" ")])]),_c('ul',{staticClass:"dropdown-menu w-100 overflow-auto mh--60"},[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"row gy-2"},_vm._l((_vm.listGroups),function(group,key){return _c('li',{key:key,staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"16px"}},[(
                            group.status_company == 'stop' ||
                            group.status_company == 'leave'
                          )?_c('span',{staticClass:"hover-linear-gradient"},[_vm._v(" "+_vm._s(key + 1)+". "+_vm._s(group.name)+"（終了） ")]):_c('router-link',{staticClass:"hover-linear-gradient",attrs:{"to":'/group/' + group.id}},[_vm._v(_vm._s(key + 1)+". "+_vm._s(group.name))]),(group.unread_count > 0)?_c('div',{staticClass:"fs-10 flex-fixed badge rounded-circle bg-count-unread flex-center h--5 w--5"},[_vm._v(" "+_vm._s(group.unread_count)+" ")]):_vm._e()],1)])}),0)])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkLogin),expression:"checkLogin"}],staticClass:"flex-1 flex-xl-none px-xl-7 hover-linear-gradient"},[_c('div',{staticClass:"dropdown",staticStyle:{"position":"initial"}},[_c('div',{staticClass:"btn-pick-up flex-center flex-column flex-xl-row text-light h--17 h-xl--14 cursor-pointer",attrs:{"type":"button","data-bs-toggle":"dropdown","aria-expanded":"false","role":"button"}},[_c('i',{staticClass:"fs-14 mb-2 mb-xl-0 me-xl-3 fas fa-tools",style:(_vm.iconColor)}),_c('span',{staticClass:"fw-xl-b fs-8 fs-xl-12 h--6 h-xl-auto",style:(_vm.titleColor)},[_vm._v(" "+_vm._s(_vm.$t("nav_menu.setting"))+" ")])]),_c('ul',{staticClass:"dropdown-menu w-100 overflow-auto mh--60"},[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"row gy-2"},_vm._l((_vm.listSettings),function(item,key){return _c('li',{key:key,staticClass:"col-12 col-md-3"},[_c('router-link',{staticClass:"hover-linear-gradient",attrs:{"to":item.link}},[_vm._v(_vm._s(key + 1)+". "+_vm._s(item.name))])],1)}),0)])])])])]),(_vm.checkLogin)?_c('div',{staticClass:"d-none d-xl-flex justify-content-start align-items-center border rounded-2 w--60 h--10 bg-light px-4 cursor-pointer outline-0",on:{"click":_vm.handleCheckLogin}},[_c('i',{staticClass:"fal fa-search fs-14"})]):_vm._e()])])]),_c('div',{staticClass:"h-100 w-100 z-1030 top-0 start-0 transition",attrs:{"id":"backdrop-pick-menu"}}),_c('GroupMenu',{on:{"emitGroupsData":_vm.emitGroupsData}}),_c('PickSettingMenu')],1)}
var staticRenderFns = []

export { render, staticRenderFns }