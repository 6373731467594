import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Todo from './modules/Todo'
import PickMenu from './modules/PickMenu'
import Theme from './modules/Theme'
import UserInfo from './modules/UserInfo'
import Language from './modules/Language'
import Setting from './modules/Setting'
import Auth from './modules/Auth'
import Notification from './modules/Notification'
import CommonTopic from './modules/CommonTopic';
import Calendar from './modules/Calendar';
import BlockUi from './modules/BlockUi';
import GroupInfo from './modules/GroupInfo';
import Chat from './modules/Chat';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    todo: Todo,
    pickMenu: PickMenu,
    theme: Theme,
    userInfo: UserInfo,
    language: Language,
    setting: Setting,
    auth: Auth,
    notification: Notification,
    commonTopic: CommonTopic,
    calendar: Calendar,
    blockUi: BlockUi,
    groupInfo: GroupInfo,
    chat: Chat
  },
  plugins: [
    createPersistedState({
      paths: ['setting', 'userInfo.user', 'auth', 'notification', 'commonTopic', 'calendar', 'groupInfo', 'chat'],
      storage: window.sessionStorage
    }),
    // createPersistedState({
    //   paths: ['setting', 'userInfo.user', 'auth', 'notification', 'commonTopic', 'calendar', 'groupInfo', 'chat']
    // })
  ]
})

export default store
