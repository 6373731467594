

























































import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })


export default class FooterMobile extends Vue {
  private currentUrl: any = window.location.origin

  created() {
    this.currentUrl = window.location.origin
  }
}
