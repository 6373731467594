import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/globalComponent'
import './assets/scss/index.scss'
import i18n from '@/lang'
import './validations/validation'
import VueLazyload from 'vue-lazyload'
import moment from 'moment'
import { BootstrapVue } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Editor from '@tinymce/tinymce-vue'
import QrcodeVue from 'qrcode.vue'
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper'
// @ts-ignore
import DatePicker from 'vue2-datepicker';
// @ts-ignore
import ScrollLoader from 'vue-scroll-loader'
import BlockUi from '@/store/modules/BlockUi'
import { getModule } from 'vuex-module-decorators'
import { Component } from 'vue-property-decorator'
import Swiper, { Autoplay, Pagination, Navigation } from 'swiper'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// @ts-ignore
import Vidle from 'v-idle'

Swiper.use([Autoplay, Pagination, Navigation])
Vue.use(VueAwesomeSwiper)

const CommonModule = getModule(BlockUi, store)

moment.locale('ja');

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('Editor', Editor)
Vue.component('DatePicker', DatePicker)
Vue.component('QrcodeVue', QrcodeVue)
Vue.component('ScrollLoader', ScrollLoader)

Vue.use(BootstrapVue)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/images/group/avatar_default_1.png'),
  loading: require('@/assets/images/ip_loading.gif'),
  attempt: 1
})
Vue.use(VueRx)
Vue.use(VuejsClipper as any)
Vue.use(ScrollLoader)
Vue.use(Vidle)

const EventBus = new Vue()

Vue.prototype.$bus = EventBus
Vue.prototype.moment = moment
Vue.prototype.$blockui = {
  show: () => {
    CommonModule.SET_ACTIVE(true)
  },
  hide: (addOverFlow?: boolean) => {
    CommonModule.SET_ACTIVE(false, addOverFlow)
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $blockui: any,
    moment: any,
    $bus: any,
  }
}

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
