

































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import PickMenu from "@/store/modules/PickMenu";
import GroupMenu from "@/views/Group/GroupMenu.vue";
import PickSettingMenu from "@/views/Setting/PickSettingMenu.vue";
import { EThemeType } from "@/models/Setting/Enum";
import GroupService from "@/services/GroupService";
import CompanyService from "@/services/CompanyService";
import { settingData } from "@/views/Setting/data";

const CommonModule = getModule(PickMenu, store);

@Component({
  components: {
    GroupMenu,
    PickSettingMenu,
  },
})
export default class NavMenu extends Vue {
  @Prop() noneFixed?: boolean;
  private nav_menu_background: Object = {};
  private searchKeyWords: string = "";
  private titleColor: any;
  private iconColor: any;
  private checkLogin: boolean = false;
  private groupActivity: any = [];
  private checkChatCompany: boolean = false;
  private listGroups: any = [];
  private listSettings: any = settingData;

  @Watch("$store.state.setting.setting.setting_themes.theme.title_background")
  @Watch("$store.state.setting.setting.setting_themes.theme.title_color")
  @Watch("$store.state.setting.setting.setting_themes.theme.nav_menu_bg_img")
  @Watch("$store.state.setting.setting.setting_themes.theme.nav_menu_bg_color")
  watchNavBgColorChange() {
    this.setTheme();
  }
  @Watch("$store")
  watchNavBgColorChangeLogOut() {
    if (store.state.auth.token == undefined) {
      this.setTheme();
    }
  }
  created() {
    this.setTheme();
    if (store.state.auth.token != undefined) {
      this.checkLogin = true;
    } else {
      this.checkLogin = false;
    }

    this.getGroupTagTarget();
  }

  setTheme() {
    this.titleColor = {
      color: "white",
    };
    this.iconColor = {
      color: "white",
    };

  }

  getGroupTagTarget() {
    GroupService.getGroupTagTarget()
      .then((res) => {
        if (res.status === 200) {
          this.groupActivity = res.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleShowGroupMenu() {
    store.state.auth.token
      ? CommonModule.REVERT_GROUP_MENU()
      : this.$router.push({
          name: "login",
          query: this.$route.query,
        });
  }

  handleCheckLogin() {
    if (this.checkLogin) {
      this.$bvModal.show("modal-search-header");
    } else {
      this.$router.push({
        name: "login",
        query: this.$route.query,
      });
    }
  }

  handleShowModal() {
    console.log(1);
  }

  clickChatCheckLogin() {
    store.state.auth.token
      ? this.$router.push({ name: "chat-list" })
      : this.$router.push({ name: "login" });
  }

  linkChat() {
    if (this.checkChatCompany) {
      return false;
    } else {
      this.$router.push({
        name: "chat-list",
      });
    }
  }
  gotoCompanyList() {
    // store.state.auth.token ?
    // this.$router.push({
    //   name: 'company-list',
    //   query: this.$route.query
    // }) :
    // this.$router.push({
    //   name: 'login',
    //   query: this.$route.query
    // })

    this.$router.push({
      name: "company-list",
      // query: this.$route.query
    });
  }

  goToGroupTarget(id: any) {
    this.$router.push({
      name: "group-target-list",
      params: {
        groupTargetId: id,
      },
    });

    // this.$router.push({
    //   name: 'company-detail',
    //   params: {
    //     companyId: this.item.id,
    //     selectTab: COMPANY_TABS.BENEFITS as any
    //     }
    // })
  }

  handleShowSettingMenu() {
    store.state.auth.token
      ? CommonModule.REVERT_SETTING_MENU()
      : this.$router.push({ name: "login" });
  }

  handleShowPickMenu() {
    if (
      this.$store.state.pickMenu.showGroupMenu ||
      this.$store.state.pickMenu.showSettingMenu
    ) {
      CommonModule.CLOSE_ALL();
    }
  }

  emitGroupsData(groups: any) {
    this.listGroups = groups;
  }

  // openModalSearchHeader() {
  //   this.$router
  //     .push({
  //       query: Object.assign({}, this.$route.query, {
  //         keyword_search: this.searchKeyWords
  //       })
  //     })
  //     .catch(() => {})
  //   this.$bvModal.show('modal-search-header')
  // }
}
