




























import { Component, Vue } from 'vue-property-decorator'
import NavMenuBar from '@/components/NavMenuBar.vue'
import GroupHeader from '@/components/Group/GroupHeader.vue'
import GroupMainNav from '@/components/Group/GroupMainNav.vue'

@Component({ components: { GroupHeader, GroupMainNav, NavMenuBar } })
export default class LayoutGroup extends Vue {}
