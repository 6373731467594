


























import { Component, Vue } from 'vue-property-decorator'
import HeaderCompact from '@/components/HeaderCompact.vue'
// import Footer from '@/components/Footer.vue'
import FooterHome from '@/components/FooterHome.vue'
import FooterMobile from '@/components/FooterMobile.vue'

@Component({ components: { HeaderCompact, FooterHome, FooterMobile } })
export default class LayoutCompactPreview extends Vue {}
