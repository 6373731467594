


























































import FooterHome from '@/components/FooterHome.vue'
import { Component, Vue } from 'vue-property-decorator'
import FooterMobile from '@/components/FooterMobile.vue'

@Component({ components: { FooterHome, FooterMobile } })
export default class LayoutAuthRegister extends Vue {
  private backgroundLogin = require('@/assets/images/S-NOTE_FE_login.png')
  goToLogin() {
    this.$router.push({ name: 'login' })
  }
}
