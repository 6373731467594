import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'pickMenu' })
class PickMenu extends VuexModule {
  showGroupMenu: boolean = false;
  showHomeMenu: boolean = false;
  showSettingMenu: boolean = false;

  @Mutation
  revertShowGroupMenu() {
    this.showGroupMenu = !this.showGroupMenu
    handleBackDrop(this.showGroupMenu)
    //close other
    this.showHomeMenu = false
    this.showSettingMenu = false
  }

  @Mutation
  revertShowSettingMenu() {
    this.showSettingMenu = !this.showSettingMenu
    handleBackDrop(this.showSettingMenu)
    //close other
    this.showHomeMenu = false
    this.showGroupMenu = false
  }

  @Mutation
  revertShowHomeMenu() {
    this.showHomeMenu = !this.showHomeMenu
    handleBackDrop(this.showHomeMenu)
    //close other
    this.showGroupMenu = false
    this.showSettingMenu = false
  }

  @Mutation
  closeAll() {
    this.showHomeMenu = false;
    this.showGroupMenu = false;
    this.showSettingMenu = false;
    handleBackDrop(false)
  }

  @Action({ rawError: true })
  REVERT_GROUP_MENU() {
    this.context.commit('revertShowGroupMenu')
  }

  @Action({ rawError: true })
  REVERT_SETTING_MENU() {
    this.context.commit('revertShowSettingMenu')
  }

  @Action({ rawError: true })
  REVERT_HOME_MENU() {
    this.context.commit('revertShowHomeMenu')
  }

  @Action({ rawError: true })
  CLOSE_ALL() {
    this.context.commit('closeAll')
  }
}

const handleBackDrop = (bool: boolean) => {
  if (bool) {
    document.body.classList.add('overflow-hidden')
    document.getElementById('backdrop-pick-menu')?.classList.add('position-fixed')
    document.getElementById('backdrop-pick-menu')?.classList.add('bg-dark-o-5')
  } else {
    document.body.classList.remove('overflow-hidden')
    document.getElementById('backdrop-pick-menu')?.classList.remove('position-fixed')
    document.getElementById('backdrop-pick-menu')?.classList.remove('bg-dark-o-5')
  }

}

export default PickMenu