var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.notifications.length && _vm.unreadFrom !== _vm.eFrom.NAVBAR)?_c('div',[_c('div',{staticClass:"form-check d-flex align-items-center mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isCheckAll),expression:"isCheckAll"}],staticClass:"form-check-input h--7 w--7 me-2",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isCheckAll)?_vm._i(_vm.isCheckAll,null)>-1:(_vm.isCheckAll)},on:{"change":[function($event){var $$a=_vm.isCheckAll,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isCheckAll=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isCheckAll=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isCheckAll=$$c}},_vm.selectAllNotifications]}}),_c('button',{staticClass:"btn btn-outline-dark-tint-less button-border-radius",attrs:{"disabled":_vm.disableMarkReadAll},on:{"click":_vm.markReadedNotifications}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_mark_as_read'))+" ")])])]):_vm._e(),(!_vm.notifications.length)?_c('div',{staticClass:"text-secondary text-center fs-14 pt-6"},[_vm._v(" "+_vm._s(_vm.$t('common.message.no_unread'))+" ")]):_vm._e(),_c('div',{staticClass:"notification-list"},_vm._l((_vm.notifications),function(notification,index){return _c('div',{key:index,staticClass:"hover-linear-gradient"},[_c('div',{staticClass:"d-flex align-items-center minh--17 ",class:{ 'minh-xl--20': _vm.unreadFrom !== _vm.eFrom.NAVBAR }},[_c('label',{staticClass:"d-flex flex-column justify-content-between px-2",class:{
            'px-3': notification.unread_type !== _vm.eUnreadType.CHAT_CONTENT
          },attrs:{"for":("checkbox-" + index)}},[_c('div',{staticClass:"flex-center"},[(notification.unread_type !== _vm.eUnreadType.CHAT_CONTENT)?_c('div',{staticClass:"flex-fixed position-relative"},[_vm._l((notification.unread_icon),function(img_path,index){return _c('img',{key:index,staticClass:"rounded-pill img-cover flex-fixed me-1 h--9 w--9",attrs:{"src":_vm.getUnreadNotificationAvatar(
                    notification.unread_type,
                    notification.unread_icon,
                    index
                  )}})}),(notification.emergency_contact)?_c('i',{staticClass:"fas fa-exclamation-circle fs-12 position-absolute end-0 bottom-0 text-danger bg-light rounded-pill",class:{ 'fs-xl-15': _vm.unreadFrom !== _vm.eFrom.NAVBAR }}):_vm._e()],2):_c('div',{staticClass:"flex-fixed position-relative"},[(notification.unread_icon.length)?_c('ul',{staticClass:"avatar-group mb-0 h--9 w--9"},_vm._l((notification.unread_icon),function(img_path,index){return _c('li',{key:index,staticClass:"avatar-user"},[_c('img',{staticClass:"img-avatar",attrs:{"src":_vm.getUnreadNotificationAvatar(
                        notification.unread_type,
                        notification.unread_icon,
                        index
                      )}})])}),0):_c('ul',{staticClass:"avatar-group mb-0 h--9 w--9"},[_c('li',{staticClass:"avatar-user"},[_c('img',{staticClass:"img-avatar",attrs:{"src":_vm.avatarDefault}})])])]),_c('div',{staticClass:"w--6 px-1 me-2 text-primary",class:{
                ' fs-20 fs-xl-24': _vm.unreadFrom !== _vm.eFrom.NAVBAR,
                'fs-22': _vm.unreadFrom === _vm.eFrom.NAVBAR
              }},[_c('i',{staticClass:"far",class:_vm.getUnreadNotificationIcon(notification.unread_type)})])]),(_vm.unreadFrom !== _vm.eFrom.NAVBAR)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(notification.selected),expression:"notification.selected"}],staticClass:"form-check-input mt-1 ms-xl-1",class:{
              'ms-1 ms-xl-2':
                notification.unread_type === _vm.eUnreadType.CHAT_CONTENT
            },attrs:{"id":("checkbox-" + index),"type":"checkbox"},domProps:{"checked":Array.isArray(notification.selected)?_vm._i(notification.selected,null)>-1:(notification.selected)},on:{"change":[function($event){var $$a=notification.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(notification, "selected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(notification, "selected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(notification, "selected", $$c)}},_vm.updateSelectAllNotifications]}}):_vm._e()]),_c('div',{staticClass:"pe-3 py-1 w-100 cursor-pointer",class:{
            'justify-content-xl-between d-xl-flex py-xl-4':
              _vm.unreadFrom !== _vm.eFrom.NAVBAR
          },on:{"click":_vm.hideDropdownUnread}},[(notification.status_company == 'stop' || notification.status_company == 'leave')?_c('router-link',{staticClass:"content pe-13 mb-1",class:{ 'w-xl-50 fs-12 fs-xl-15': _vm.unreadFrom !== _vm.eFrom.NAVBAR },attrs:{"to":''}},[_c('div',{staticClass:"text-line-clamp-1 fwb"},[(notification.unread_type === _vm.eUnreadType.CHAT_CONTENT)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.handleChatTitle(notification)),expression:"handleChatTitle(notification)",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.handleLongText(_vm.handleChatTitle(notification)))+" ")]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(notification.title),expression:"notification.title",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.handleLongText(notification.title))+" ")])]),(
                notification.unread_type !==
                  _vm.eUnreadType.GROUP_SHARED_FOLDER &&
                  notification.unread_type !==
                    _vm.eUnreadType.COMMON_SHARED_FOLDER
              )?_c('div',{staticClass:"overflow-hidden h--8",class:{
                'text-secondary-tint-less':
                  !notification.content ||
                  notification.content === 'ATTACH_FILE' ||
                  notification.content === 'MESSAGE_DELETED',
                'all-child-reset chat':
                  notification.unread_type === _vm.eUnreadType.CHAT_CONTENT
              }},[_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 all-child-reset",domProps:{"innerHTML":_vm._s(_vm.handleContentTextNull(notification))}})]):_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 overflow-hidden h--8"},[(notification.content)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(notification.content),expression:"notification.content",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1",domProps:{"innerHTML":_vm._s(notification.content)}}):_c('span',{staticClass:"text-secondary-tint-less"},[_vm._v(" "+_vm._s(_vm.handleContentTextNull(notification))+" ")])])]):_c('router-link',{staticClass:"content pe-13 mb-1",class:{ 'w-xl-50 fs-12 fs-xl-15': _vm.unreadFrom !== _vm.eFrom.NAVBAR },attrs:{"to":_vm.goToDetailNotification(index)}},[_c('div',{staticClass:"text-line-clamp-1 fwb"},[(notification.unread_type === _vm.eUnreadType.CHAT_CONTENT)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.handleChatTitle(notification)),expression:"handleChatTitle(notification)",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.handleLongText(_vm.handleChatTitle(notification)))+" ")]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(notification.title),expression:"notification.title",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.handleLongText(notification.title))+" ")])]),(
                notification.unread_type !==
                  _vm.eUnreadType.GROUP_SHARED_FOLDER &&
                  notification.unread_type !==
                    _vm.eUnreadType.COMMON_SHARED_FOLDER
              )?_c('div',{staticClass:"overflow-hidden h--8",class:{
                'text-secondary-tint-less':
                  !notification.content ||
                  notification.content === 'ATTACH_FILE' ||
                  notification.content === 'MESSAGE_DELETED',
                'all-child-reset chat':
                  notification.unread_type === _vm.eUnreadType.CHAT_CONTENT
              }},[_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 all-child-reset",domProps:{"innerHTML":_vm._s(_vm.handleContentTextNull(notification))}})]):_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 overflow-hidden h--8"},[(notification.content)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(notification.content),expression:"notification.content",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1",domProps:{"innerHTML":_vm._s(notification.content)}}):_c('span',{staticClass:"text-secondary-tint-less"},[_vm._v(" "+_vm._s(_vm.handleContentTextNull(notification))+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-1"},[(notification.status_company == 'stop')?_c('router-link',{staticClass:"d-flex align-items-center flex-1 h-100",class:{
                'justify-content-xl-between': _vm.unreadFrom !== _vm.eFrom.NAVBAR
              },attrs:{"to":''}},[_c('div',{staticClass:"updater-name pe-3"},[(!notification.allow_anonymous_comment)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('停止された企業様'),expression:"'停止された企業様'",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1",class:{
                    'text-secondary-tint-less':
                      notification.user_active_status
                  }},[_vm._v(" 停止された企業様 ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('groups.new_feed.anonymous'))+" ")])]),_c('span',{staticClass:"flex-fixed pe-3"},[_vm._v(" "+_vm._s(_vm.formatDate(notification.updated_at))+" ")])]):(notification.status_company == 'leave')?_c('router-link',{staticClass:"d-flex align-items-center flex-1 h-100",class:{
                'justify-content-xl-between': _vm.unreadFrom !== _vm.eFrom.NAVBAR
              },attrs:{"to":''}},[_c('div',{staticClass:"updater-name pe-3"},[(!notification.allow_anonymous_comment)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('退会された企業様'),expression:"'退会された企業様'",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1",class:{
                    'text-secondary-tint-less':
                      notification.user_active_status
                  }},[_vm._v(" 退会された企業様 ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('groups.new_feed.anonymous'))+" ")])]),_c('span',{staticClass:"flex-fixed pe-3"},[_vm._v(" "+_vm._s(_vm.formatDate(notification.updated_at))+" ")])]):_c('router-link',{staticClass:"d-flex align-items-center flex-1 h-100",class:{
                'justify-content-xl-between': _vm.unreadFrom !== _vm.eFrom.NAVBAR
              },attrs:{"to":_vm.goToDetailNotification(index)}},[_c('div',{staticClass:"updater-name pe-3"},[(!notification.allow_anonymous_comment)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(notification.name_company != '' ? _vm.getUpdaterName(notification).name + '@' + notification.name_company : _vm.getUpdaterName(notification).name),expression:"notification.name_company != '' ? getUpdaterName(notification).name + '@' + notification.name_company : getUpdaterName(notification).name",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1",class:{
                    'text-secondary-tint-less':
                      notification.user_active_status
                  }},[_vm._v(" "+_vm._s(notification.name_company != '' ? _vm.getUpdaterName(notification).text + '@' + notification.name_company : _vm.getUpdaterName(notification).text)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('groups.new_feed.anonymous'))+" ")])]),_c('span',{staticClass:"flex-fixed pe-3"},[_vm._v(" "+_vm._s(_vm.formatDate(notification.updated_at))+" ")])]),_c('div',{staticClass:"minw--30"},[_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light button-border-radius text-nowrap",class:_vm.checkAdmin && notification.group_can_chat == 1 || notification.status_company == 'leave' || notification.status_company == 'stop'? 'disabled': '',on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.markReadedNotification(index)}]}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_mark_as_read'))+" ")])])],1)],1)]),(_vm.unreadFrom !== _vm.eFrom.NAVBAR)?_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary"}):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }