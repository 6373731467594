








































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import FormatDate from '@/helpers/FormatDate'
import {
  EFavoriteType,
  EFileFormat,
  EFrom,
  EUserStatus,
  Favorite,
  UserModel
} from '@/models'
import { unreadNotificationRef } from '@/firebase'
import { getFavouriteCount } from '@/helpers/GetNotificationCount'
import NotificationService from '@/services/NotificationService'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import PreviewText from '@/helpers/PreviewText'
import GroupTopicService from '@/services/GroupTopicService'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import CommonTopicService from '@/services/CommonTopicService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'
import ChatService from '@/services/ChatService'
import CalendarService from '@/services/CalendarService'
import { getModule } from 'vuex-module-decorators'
import CommonTopicVueX from '@/store/modules/CommonTopic'
import store from '@/store'
//@ts-ignore
import _ from 'lodash'

const CommonTopicModule = getModule(CommonTopicVueX, store)

@Component
export default class FavouriteNotification extends Vue {
  @Prop() favoriteFrom!: EFrom
  @Prop() private toggleFavorite!: boolean
  @Prop() private triggerFavorite!: boolean
  private startLoading: boolean = true
  private favorites: Favorite[] = []
  private avatarDefault = require('@/assets/images/avatar-default.png')
  private avatarCommonTopic = require('@/assets/images/logo-cs-1.png')
  private avatarGroupDefault = require('@/assets/images/group/avatar_default_1.png')
  private eFileFormat = EFileFormat
  private eFavoriteType = EFavoriteType
  private eFrom = EFrom
  private page: number = 1
  private maxPage: number = 1
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private ATTACH_FILE: string = 'ATTACH_FILE'
  private MESSAGE_DELETED: string = 'MESSAGE_DELETED'
  private LEAVE_GROUP_CHAT: string = 'LEAVE_GROUP_CHAT'
  private LEAVE_SYSTEM: string = 'LEAVE_SYSTEM'
  private unsubscribe: any

  @Watch('$store.state.setting.setting.setting_display.number_item_lists')
  getFavoritesByChangeLimit(){
    this.limit = this.$store.state.setting.setting.setting_display
        .number_item_lists
    this.getFavorites()
  }


  @Watch('toggleFavorite')
  watchChangeNotificationFavorite() {
    if (this.triggerFavorite) this.getFavorites()
  }

  async created() {
    if (this.favoriteFrom !== EFrom.NAVBAR) {
      this.getFavorites()
    }

    // firebase get change unread notification
    this.handleRealtime()
  }

  destroyed() {
    this.unsubscribe()
  }

  @Watch('$store.state.userInfo.user.info.id')
  handleRealtime() {
    let trackingRealtime = true
    const userId = this.$store.state.userInfo.user.info.id
    if (!userId) return
    const start = this.moment()
      .subtract(1, 'm')
      .toDate()
    this.unsubscribe = unreadNotificationRef
      .where('userIds', 'array-contains', userId)
      .where('createdAt', '>', start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false
        } else {
          if (this.favoriteFrom !== EFrom.NAVBAR) {
            this.getFavorites()
          }
          if (this.favoriteFrom === EFrom.NAVBAR) {
            getFavouriteCount()
          }
        }
      })
  }

  /**
   * Call API get list favorite
   */
  // @Watch('$store.state.notification.triggerFavorite')
  // async rebindFavorite() {
  //   this.getFavorites()
  // }

  async getFavorites(loadMore?: boolean) {
    await NotificationService.getNotificationFavorite(this.limit, this.page)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.map((item: any) => new Favorite(item))
          if (loadMore) {
            this.favorites = [...this.favorites, ...data]
          } else {
            this.favorites = data
          }
          this.maxPage = res.data.last_page
          if (this.favorites.length) {
            this.$emit('diableSeeMoreFavoriteNavHeader', false)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => (this.startLoading = false))
  }

  /**
   * Call API for loadmore
   */
  loadMore() {
    ++this.page
    this.getFavorites(true)
  }

  formatDate(date: string) {
    return FormatDate.formatCompact(date)
  }

  getFileFormat(fileName: string) {
    return CheckFileFormat.getFileFormatWithFileName(fileName)
  }

  formatDateWithDays(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  /**
   * Handle chat title for favortie
   *
   * If this chat is private chat (2 user) => name will NULL and title with be the other user
   *
   * IF this chat is group chat (more than 2 user) => name will bi the group chat's name
   */
  handleChatTitle(chat: Favorite) {
    if (!chat.only_user_chat) return chat.name

    if (!chat.only_user_chat && !chat.group_avatar.length) return chat.name

    let names = []

    if (chat.only_user_chat && chat.user_leave_chat) {
      names = [
        this.hanldeUserStatus(
          chat.user_leave_chat.name,
          chat.user_leave_chat.leaveType
        )
      ]
    } else {
      names = chat.title ? chat.title.split(',') : []
    }

    if (names.length > 2) {
      return (
        names.join(`${this.$t('common.suffix.san')}${this.$t('chat.comma')}`) +
        this.$t('chat.chat_group_name_with', {
          other_user_count: names.length - 2
        })
      )
    } else {
      return (
        names
          .map(name => `${name}${this.$t('common.suffix.san')}`)
          .join(`${this.$t('chat.comma')}`) + this.$t('chat.chat_with')
      )
    }
  }

  /**
   * Handle null content text
   */
  handleContentTextNull(favor: Favorite) {
    let content = '<div>' + favor.content + '</div>'
    let doc = new DOMParser().parseFromString(content, 'text/html')
    let wrapper: any = doc.querySelector('div')
    content = wrapper.innerText
    if (
      content &&
      !content.includes(this.ATTACH_FILE) &&
      !content.includes(this.MESSAGE_DELETED) &&
      !content.includes(this.LEAVE_GROUP_CHAT) &&
      !content.includes(this.LEAVE_SYSTEM)
    ) {
      return favor.content
    }
    switch (favor.favorite_target_type) {
      case EFavoriteType.COMMON_TOPIC:
      case EFavoriteType.GROUP_TOPIC:
        if (favor.is_topic_comment)
          return this.$t('common.message.topic_comment_no_content')
        return this.$t('common.message.topic_no_content')
      case EFavoriteType.COMMON_FILE:
      case EFavoriteType.GROUP_FILE:
        return this.$t('common.message.file_no_content')
      case EFavoriteType.CHAT:
        const text = content ? content.split(',')[0] : ''
        const name = content ? content.split(',')[1] : ''

        switch (text) {
          case this.ATTACH_FILE:
            return this.$t('common.message.chat_no_content')
          case this.MESSAGE_DELETED:
            return this.$t('chat.list.msg.msg_deleted')
          case this.LEAVE_GROUP_CHAT:
            return this.$t('chat.list.msg.leave_group_chat', {
              name: name
            })
          case this.LEAVE_SYSTEM:
            return this.$t('chat.list.msg.leave_system', {
              name: name
            })
        }
        return this.$t('common.message.chat_no_content')
      default:
        return ''
    }
  }

  /**
   * Get fontawsome icon by file extension
   */
  getIconName(fileName: string) {
    return CheckFileFormat.getIconClassNameWithFileName(fileName)
  }

  handleLongText(text: string) {
    return _.truncate(text, {
      length: 50,
      separator: ' '
    })
  }

  handleUpdater(favor: Favorite) {
    let content = '<div>' + favor.content + '</div>'
    let doc = new DOMParser().parseFromString(content, 'text/html')
    let wrapper: any = doc.querySelector('div')
    content = wrapper.innerText
    if (
      content &&
      favor.favorite_target_type === EFavoriteType.CHAT &&
      (content.includes(this.ATTACH_FILE) ||
        content.includes(this.MESSAGE_DELETED) ||
        content.includes(this.LEAVE_GROUP_CHAT) ||
        content.includes(this.LEAVE_SYSTEM))
    ) {
      return {
        name: this.$t('common.suffix.system'),
        text: this.$t('common.suffix.system')
      }
    }

    let text = this.hanldeUserStatus(favor.user_name, favor.user_active_status)
    const preview = PreviewText.covertToPreviewText(text, 50)
    return {
      name: text,
      text: preview.text
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  goToFavourite() {
    if (this.$route.name === 'favourite') {
      this.$emit('hideDropdownFavourite')
    } else {
      this.$router.push({ name: 'favourite' })
    }
  }

  handleMarkRead(favor: Favorite, index: number) {
    getFavouriteCount()
    //handle rebind unred count
    CommonTopicModule.SET_RELOAD_UNREAD(true)
    this.favorites[index].is_read = true
    switch (favor.favorite_target_type) {
      case EFavoriteType.GROUP_TOPIC:
        GroupTopicService.markAsRead(String(favor.group_id), favor.id)
        break
      case EFavoriteType.GROUP_FILE:
        GroupSharedFolderService.markFileAsRead(
          String(favor.group_id),
          favor.id
        )
        break
      case EFavoriteType.COMMON_TOPIC:
        CommonTopicService.markAsCommonTopicRead(favor.id)
        break
      case EFavoriteType.COMMON_FILE:
        CommonSharedFolderService.markCommonFileAsRead(favor.id)
        break
      case EFavoriteType.CALENDAR:
        CalendarService.markEventCalendarReaded(favor.id)
        break
      case EFavoriteType.CHAT:
        ChatService.markReadGroupChat({
          group_chat_id: favor.id,
          count_message_not_seen: 0
        })
        break
      default:
        break
    }
  }

  hideDropdownFavourite(event: any) {
    if(!event.ctrlKey) {
      this.$emit('hideDropdownFavourite')
    }
  }

  goToDetail(name: string, params: any, query?: any) {
    return { name: name, params: params, query: query ? query : {} }
    // if (this.$route.name === name) {
    //   this.$router.replace({ name: name, params: params, query: query ? query : {} }).catch(() => {})
    // } else {
    //   this.$router.push({ name: name, params: params, query: query ? query : {} }).catch(() => {})
    // }
  }

  goToNotificationDetail(favor: Favorite) {
    let topic_comment_id = favor.topic_comment_id

    switch (favor.favorite_target_type) {
      case EFavoriteType.GROUP_TOPIC:
        return this.goToDetail('group-new-feed-topic-detail', {
          groupId: `${favor.group_id}`,
          topicId: `${favor.id}`,
          from: this.$route.path
        }, topic_comment_id ? { commentSearchId: topic_comment_id } : {})
        
      case EFavoriteType.GROUP_FILE:
        return this.goToDetail('group-shared-folder-file-detail', {
          groupId: `${favor.group_id}`,
          fileId: `${favor.id}`,
          from: this.$route.path
        })
        
      case EFavoriteType.COMMON_TOPIC:
        return this.goToDetail('common-topic-new-feed-topic-detail', {
          topicId: `${favor.id}`,
          from: this.$route.path
        }, topic_comment_id ? { commentSearchId: topic_comment_id } : {})
        
      case EFavoriteType.COMMON_FILE:
        return this.goToDetail('common-topic-shared-folder-file-detail', {
          fileId: `${favor.id}`,
          from: this.$route.path
        })
        
      case EFavoriteType.CALENDAR:
        return this.goToDetail('calendar-preview', { id: `${favor.id}` })
        
      case EFavoriteType.CHAT:
        return this.goToDetail('chat-detail', {
          groupChatId: `${favor.id}`,
          from: this.$route.path
        })
        
      default:
        return {}
    }
  }
}
