import { IUserGroupRole } from '@/models'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'userInfo' })
class UserInfo extends VuexModule {
  user_default: any = {
    info: {
      email: '',
      name: '',
      id: '',
      name_kana: ''
    },
    profile: {
      email_option: 1,
      avatar: require('@/assets/images/avatar-default.png'),
      avatar_file_name: "",
      last_name: '',
      first_name: '',
      last_name_kana: '',
      first_name_kana: '',
      self_introduction: '',
      is_admin_company: '',
      name_admin_company: ''
    },
    company_info: {
      company_info_display_option: 0,
      company_name: '',
      department: '',
      job_title: '',
      zipcode: '',
      prefecture: '',
      city: '',
      other_address: '',
      building_name: '',
      phone: '',
      url: ''
    },
    personal_info: {
      gender: null,
      birthday_year: null,
      birthday_month: null,
      birthday_day: null,
      birthday_option: 0,
      nearest_station: '',
      zipcode: null,
      prefecture: '',
      prefecture_option: 0,
      city: '',
      other_address: '',
      building_name: '',
      phone: [''],
      url: ['']
    },
    group_role: {
      group_id: 0,
      role_admin: false,
      role_invite: false,
      role_edit_shared_folder: false,
      role_edit_topic: false,
    },
    common_topic_role: false,
    current_group_max_file_size: 25
  }
  user: any = this.user_default

  //Handle User Id
  @Mutation
  handleUserId(user_id: any) {
    this.user.info.id = user_id
  }

  // User Info
  @Mutation
  handleUserInfo(user_info: any) {
    this.user.info = user_info
  }

  @Mutation
  updateUserName(new_name: any) {
    this.user.info.name = new_name
  }


  @Action({ rawError: true })
  HANDLE_USER_ID(user_id: any) {
    this.context.commit('handleUserId', user_id)
  }

  @Action({ rawError: true })
  HANDLE_USER_INFO(user_info: any) {
    this.context.commit('handleUserInfo', user_info)
  }

  @Action({ rawError: true })
  UPDATE_USER_NAME(new_name: any) {
    this.context.commit('updateUserName', new_name)
  }

  @Action({ rawError: true })
  RESET_DEFAULT() {
    this.context.commit('resetDefault')
  }

  //User Profile
  @Mutation
  handleUserProfile(user_profile: any) {
    this.user.profile = user_profile
  }

  @Mutation
  changeEmail(new_email: string) {
    this.user.info.email = new_email
  }

  @Action({ rawError: true })
  HANDLE_USER_PROFILE(user_profile: any) {
    this.context.commit('handleUserProfile', user_profile)
  }

  @Action({ rawError: true })
  CHANGE_EMAIL(new_email: string) {
    this.context.commit('changeEmail', new_email)
  }

  //User Company Info
  @Mutation
  handleUserCompanyInfo(user_company_info: any) {
    this.user.company_info = user_company_info
    if (user_company_info.company_info_display_option === null) {
      this.user.company_info.company_info_display_option = false
    }
  }

  @Action({ rawError: true })
  HANDLE_USER_COMPANY_INFO(user_company_info: any) {
    this.context.commit('handleUserCompanyInfo', user_company_info)
  }

  //User Personal Info
  @Mutation
  handleUserPersonalInfo(user_personal_info: any) {
    this.user.personal_info = user_personal_info
    if (user_personal_info.birthday_option === null) {
      this.user.personal_info.birthday_option = 0
    }
  }

  @Action({ rawError: true })
  HANDLE_USER_PERSONAL_INFO(user_personal_info: any) {
    this.context.commit('handleUserPersonalInfo', user_personal_info)
  }

  //user-group role
  @Action({ rawError: true })
  UPDATE_USER_GROUP_ROLE(user_group_role: IUserGroupRole) {
    this.context.commit('handleUserGroupRole', user_group_role)
  }

  @Mutation
  handleUserGroupRoleInvite(roleInvite: boolean) {
    this.user.group_role.role_invite = roleInvite
  }

  //user-group role invite
  @Action({ rawError: true })
  UPDATE_USER_GROUP_ROLE_INVITE(roleInvite: boolean) {
    this.context.commit('handleUserGroupRoleInvite', roleInvite)
  }

  @Mutation
  handleUserGroupRole(user_group_role: IUserGroupRole) {
    this.user.group_role = user_group_role
  }

  //user-common role
  @Action({ rawError: true })
  UPDATE_USER_COMMON_TOPIC_ROLE(user_common_topic_role: boolean) {
    this.context.commit('handleUserCommonTopicRole', user_common_topic_role)
  }

  @Mutation
  handleUserCommonTopicRole(user_common_topic_role: boolean) {
    this.user.common_topic_role = user_common_topic_role
  }

  //current group max file size
  @Action({ rawError: true })
  UPDATE_CURRENT_GROUP_MAX_FILE_SIZE(max: number) {
    this.context.commit('updateCurrentGroupMaxFileSize', max)
  }

  @Mutation
  updateCurrentGroupMaxFileSize(max: number) {
    this.user.current_group_max_file_size = max
  }

  @Mutation
  resetDefault() {
    this.user = this.user_default
  }
}

export default UserInfo
