





















































import { Component, Vue, Watch } from "vue-property-decorator";
import { ITabModel, IUserGroupRole } from "@/models";
import NavMenuBar from "@/components/NavMenuBar.vue";

@Component({ components: { NavMenuBar } })
export default class GroupMemberNav extends Vue {
  private tabs: ITabModel[] = [];

  created() {
    this.initTab();
  }

  @Watch("$route.params.groupId")
  @Watch("$store.state.userInfo.user.group_role")
  initTab() {
    const groupRole: IUserGroupRole = this.$store.state.userInfo.user
      .group_role;
    this.tabs = [
      {
        id: 0,
        displayName: String(this.$t("groups.member_list.member.title")),
        routerName: "group-members-list",
        routerLink: { name: "group-members-list" },
      },
      {
        id: 1,
        displayName: String(this.$t("groups.member_list.qr_code.title")),
        routerName: "group-members-qr-code-invite",
        routerLink: { name: "group-members-qr-code-invite" },
        disable: !groupRole.role_invite,
      },
      {
        id: 2,
        displayName: String(this.$t("groups.member_list.invited.title")),
        routerName: "group-members-invited",
        routerLink: { name: "group-members-invited" },
        disable: !groupRole.role_invite,
      },
      // {
      //   id: 3,
      //   displayName: String(this.$t('groups.member_list.join_request.title')),
      //   routerName: 'group-members-join-request',
      //   routerLink: { name: 'group-members-join-request' },
      //   disable: !groupRole.role_invite
      // },
      {
        id: 4,
        displayName: String(this.$t("groups.member_list.role.title")),
        routerName: "group-members-role",
        routerLink: { name: "group-members-role" },
        disable: !groupRole.role_admin,
      },
    ];
  }
}
