









import CheckDevice from '@/helpers/CheckDevice'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BlockUI extends Vue {
  @Prop({ default: false }) private active!: boolean
  private isMobile: boolean = CheckDevice.isMobile()
}
