import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { AddEditEquipmentParams, DeleteFolderParams, RenameFolderParams, TopicFilePrams } from '@/models'

class GroupService {

  getAllGroupCompact(filter?: string[]) {
    let filterText = ''
    if (filter) {
      filterText = '&filter=' + filter.join(';')
    }
    return axiosApiInstance.get(`${Const.api_url}members/me/groups?limit=99999${filterText}`)
  }

  getGroupById(groupId: string, filter?: string[]) {
    let filterText = ''
    if (filter) {
      filterText = '?filter=' + filter.join(';')
    }
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId + filterText}`)
  }

  checkMemberGr(params: any) {
    return axiosApiInstance.get(`${Const.api_url}check-member-group?group_id=${params.groupId}&user_id=${params.userId}`)
  }

  addUserJoinGroup(params: any) {
    return axiosApiInstance.post(`${Const.api_url}add-user-join-group?group_id=${params.groupId}&user_id=${params.userId}`)
  }

  getGroupTagTarget() {
    return axiosApiInstance.get(Const.api_url + `group-tag-target`);
  }

  getGroupByGroupTagTargetId(params: any) {
    return axiosApiInstance.get(Const.api_url + `get-group-by-group-tag-target-id/${params.groupTargetId}?limit=${params.limit}`)
  }

  getNewInviteLink(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/get-new-invite-link`)
  }

  checkGroupLink(groupLink: string) {
    return axiosApiInstance.get(Const.api_url + `groups/${groupLink}`)
  }

  getLoginedUserGroupTags(group_id: any = '') {
    if (group_id) {
      return axiosApiInstance.get(Const.api_url + `members/me/group-tags?group_id=${group_id}&filter=id;name`)
    }
    return axiosApiInstance.get(Const.api_url + 'members/me/group-tags?filter=id;name')
  }

  getEquipmentsByGroupId(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/equipments`)
  }

  getGroupAuth() {
    return axiosApiInstance.get(`${Const.api_url}permissions`)
  }

  getTopicFileCompact(params: TopicFilePrams) {
    // return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/data-group-top?unreadOnly=${params.unread_only}&orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
    return axiosApiInstance.get(`${Const.api_url}groups/${params.group_id}/data-group-top?unreadOnly=${params.unread_only}&orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getUnreadCount(groupId: string) {
    // return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/get-count-unread-data-group-top`)
    return axiosApiInstance.get(`${Const.api_url}groups/${groupId}/get-count-unread-data-group-top`)
  }

  getGroupNews(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}informations/groups/${groupId}`)
  }

  registerEquipment(params: AddEditEquipmentParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/equipments`, params)
  }

  editEquipment(params: AddEditEquipmentParams) {
    return axiosApiInstance.patch(`${Const.api_url}members/me/groups/${params.group_id}/equipments/${params.equipment_id}`, params)
  }

  deleteEquipment(params: AddEditEquipmentParams) {
    return axiosApiInstance.delete(`${Const.api_url}members/me/groups/${params.group_id}/equipments/${params.equipment_id}`)
  }

  renameFolder(params: RenameFolderParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/folder/rename-folder`, params)
  }

  deleteFolder(params: DeleteFolderParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/folder/delete-folder`, params)
  }

  cleanBin(groupId: string) {
    return axiosApiInstance.delete(`${Const.api_url}members/me/groups/${groupId}/clean-bin`)
  }

  createGroupBasicInfo(params: FormData) {
    return axiosApiInstance.post(`${Const.api_url}groups/create`, params)
  }

  updateGroupBasicInfo(groupInfo: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupInfo.get('id')}`, groupInfo)
  }

  downloadFiles(groupId: string, fileIds: number[]) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/download-files`, { file_ids: fileIds, is_comment_file: false }, { responseType: 'arraybuffer' })
  }

  getGroupOrder(page: number, limit: number, notGetCompanyDefault? : string ) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups-order?page=${page}&limit=${limit}&notGetCompanyDefault=${notGetCompanyDefault}`)
  }

  getGroupUnread(page: number, limit: number, company? : string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/unread/group-data?page=${page}&limit=${limit}&company=${company}`)
  }

  uploadFileTiny(params: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/upload-file-by-tiny`, params)
  }

  markReadGroup(group_id: number, data: any) {
    return axiosApiInstance.post(`${Const.api_url}members/me/mark-read-each-item/${group_id}}`, data)
  }

  markReadAllGroup(group_id: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/mark-read-group/${group_id}`)
  }

  getGroupDefaultIcon() {
    return axiosApiInstance.get(`${Const.api_url}members/me/group-icon`)
  }

  getAreaByGroup(id: any) {
    return axiosApiInstance.get(`${Const.api_url}list-name-area/${id}`)
  }

  checkGroupDefault(id: any) {
    return axiosApiInstance.get(`${Const.api_url}check-group-default/${id}`)
  }
}

export default new GroupService
