import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'groupInfo' })
class GroupInfo extends VuexModule {
  header_file_name: any = ''
  icon_file_name: any = ''

  @Mutation
  handleHeaderFileName(new_header_file_name: any) {
    this.header_file_name = new_header_file_name
  }

  @Mutation
  handleIconFileName(new_icon_file_name: any) {
    this.icon_file_name = new_icon_file_name
  }

  @Action
  HANDLE_HEADER_FILE_NAME(count: any) {
    this.context.commit('handleHeaderFileName', count)
  }

  @Action
  HANDLE_ICON_FILE_NAME(new_icon_file_name: any) {
    this.context.commit('handleIconFileName', new_icon_file_name)
  }
}
export default GroupInfo