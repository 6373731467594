import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import {
  InviteParams,
  RegisterMember,
  TempUserParams,
  IUpdateRoleParams,
  RequestJoinParams,
  RequestToJoinParams,
  AdminRegisterMember,
  RequestJoinGetParams,
  MemberNameByGroupParams,
  MemberNameBySearchParams,
} from '@/models'
class GroupMemberService {

  getLoginUserRole(groupId: number) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/roles`)
  }

  getAllMembersByGroupId(groupId: string, page: number, limit: number) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/members?filter=id;name;nickname&limit=${limit}&page=${page}`)
  }

  getInvitedMembersByGroupId(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/invite-members`)
  }

  getJoinMembersByGroupId(params: RequestJoinGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_link}/join-requests?limit=${params.limit}&page=${params.page}`)
  }

  checkInvitedUserUniqueUrl(token: string) {
    return axiosApiInstance.get(`${Const.api_url}invite-members/${token}`)
  }

  checkJoinRequestUserUniqueUrl(token: string) {
    return axiosApiInstance.get(`${Const.api_url}join-requests/${token}`)
  }

  getMembersName(params: MemberNameBySearchParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/not-in-group-members?search=name:${params.name};name_kana:${params.name}&limit=${params.limit}&page=${params.page}&filter=id;name`)
  }

  getMemberNameByGroup(params: MemberNameByGroupParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/not-in-group-members?group_id=${params.group_search_id}&limit=${params.limit}&page=${params.page}&filter=id;name`)
  }

  findAdminWithoutMe(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/has-other-admin`)
  }

  leaveCurrentGroup(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/leave`)
  }

  checkExistMails(groupId: string, mails: string[]) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}​/check-exist-mails`, { mails: mails })
  }

  sendInviteToUser(params: InviteParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/invite-members`, params)
  }
  
  sendInviteToUserFromFe(params: InviteParams) {
    return axiosApiInstance.post(`${Const.api_url}storeUserToGroupDefault`, params)
  }

  reInvitedMember(groupId: string, token: string) {
    return axiosApiInstance.put(`${Const.api_url}members/me/groups/${groupId}/invite-members/${token}`)
  }

  delStopInvite(groupId: string, token: string) {
    return axiosApiInstance.delete(`${Const.api_url}members/me/groups/${groupId}/invite-members/${token}`)
  }

  registerAsMemberByAdmin(params: AdminRegisterMember) {
    return axiosApiInstance.post(`${Const.api_url}members/groups/${params.group_id}/admin-registers`, params)
  }

  registerAsMember(params: RegisterMember) {
    return axiosApiInstance.post(`${Const.api_url}members/registers/${params.type}`, params)
  }

  registerAsTempMember(params: TempUserParams) {
    return axiosApiInstance.post(`${Const.api_url}groups/${params.group_link}/join-requests`, params)
  }

  requestJoin(params: RequestJoinParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_link}/join-requests`, params)
  }

  approvalJoinRequest(params: RequestToJoinParams) {
    return axiosApiInstance.put(`${Const.api_url}members/me/groups/${params.group_link}/join-requests/${params.id}`)
  }

  approvalAllJoinRequest(params: RequestToJoinParams) {
    return axiosApiInstance.put(`${Const.api_url}members/me/groups/${params.group_link}/join-requests-approval-all`)
  }

  deniedJoinRequest(params: RequestToJoinParams) {
    return axiosApiInstance.delete(`${Const.api_url}members/me/groups/${params.group_link}/join-requests/${params.id}`)
  }

  updateMembersRole(params: IUpdateRoleParams) {
    return axiosApiInstance.post(`${Const.api_url}groups/${params.group_id}/update-roles`, params)
  }

  listMembersRole(groupId: string, memberIds: number[]) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/list-role-member-group`, { member_ids: memberIds })
  }

  removeMember(groupId: string, memberId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/delete-user/${memberId}`)
  }

  getGroupDefault() {
    return axiosApiInstance.post(`${Const.api_url}getGroupDefaultForFe`)
  }
}

export default new GroupMemberService
