import { ILanguage } from './../../models/Setting/Interface';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import i18n from '@/lang/index';

@Module({ namespaced: true, name: 'language' })
class Language extends VuexModule {
  language: ILanguage = {
    id: 1,
    name: i18n.t('lang.ja'),
    code: 'ja'
  }


  @Mutation
  changeLanguage(new_language: ILanguage) {
    i18n.locale = new_language.code
    this.language = new_language
    this.language.name = i18n.t('lang.ja')
  }

  @Action({ rawError: true })
  CHANGE_LANGUAGE(new_language: ILanguage) {
    this.context.commit('changeLanguage', new_language)
  }
}
export default Language
