import i18n from '@/lang'
import { EUserStatus, IdName, keepFirstElementOnly } from '..'
//@ts-ignore
import _ from 'lodash'
export class GroupChatModel {
  groupChatId!: number
  gCName: string = ''
  onlyUser: boolean = false
  unReadMessageCount?: number = 0
  lastMessage: string = ''
  updatedAt?: Date
  day?: string = ''
  time?: string = ''
  lastMessageSpecial: boolean = false
  favorite: boolean = false
  senderId?: number
  senderName?: string = ''
  createdByUserId?: number
  users: UserGroupChat[] = []
  userIds?: any[] = []
  size: number = 0
  deleted_at: string = ''
  user_group_chat: any[] = []

  constructor(init?: any,
    groupChatName: string = '',
    favoriteGroupChat: boolean = false,
    timeSent: string = ''
  ) {
    if (init) {
      (this.groupChatId = init.id),
        (this.onlyUser = init.only_user),
        (this.unReadMessageCount = init.count_message_not_seen),
        (this.gCName = groupChatName),
        (this.lastMessage = init.last_message),
        (this.favorite = favoriteGroupChat),
        (this.updatedAt = init.updated_at),
        (this.createdByUserId = init.created_by_user_id),
        (this.lastMessageSpecial = !!init.last_message_special),
        (this.time = timeSent),
        (this.senderId = init.sender ? init.sender.id : init.sender_id),
        (this.senderName = init.sender ? init.sender.name : i18n.t('common.suffix.system')),
        (this.users = init.user_group_chat),
        (this.size = init.size),
        (this.deleted_at = init.deleted_at),
        (this.user_group_chat = init.user_group_chat)
    }
  }
}

export class GroupChatCompact {
  count_message_not_seen: number = 0
  created_at: string = ''
  created_by_user_id!: number
  deleted_at: string = ''
  id!: number
  last_message: string = ''
  last_message_special: boolean = false
  name: string = ''
  only_user!: number
  sender: IdName = new IdName()
  sender_id!: number
  size: string = ''
  updated_at: string = ''
  user_group_chat: UserGroupChat[] = []
  user_leave_chat!: UserLeaveGroupChat

  constructor(init?: Partial<GroupChatCompact>) {
    Object.assign(this, init)

    if (this.last_message) {
      this.last_message = '<div><span>' + this.last_message + '</span></div>'
      let doc = new DOMParser().parseFromString(this.last_message, 'text/html')
      let wrapper: any = doc.querySelector('div')
      let el: any = wrapper.firstChild
      if (el) {
        el.innerText = _.truncate(el.innerText, {
          'length': 50,
          'separator': ' '
        });
        el?.classList ? el?.classList.add("text-line-clamp-1") : ''
        this.last_message = keepFirstElementOnly(wrapper, el, wrapper.childElementCount > 1)
      }
    }
  }
}
export class UserGroupChat {
  id!: number
  name: string = ''
  name_kana: string = ''
  user_active_status!: EUserStatus
  pivot: Pivot = new Pivot()
  user_profile: UserProfileGroupChat = new UserProfileGroupChat()

  constructor(init?: Partial<UserGroupChat>) {
    Object.assign(this, init)
  }
}

export class UserLeaveGroupChat {
  id!: number
  leaveType: EUserStatus = EUserStatus.NORMAL
  name: string = ''
}

export class Pivot {
  check_seen_group!: number
  count_message_not_seen: number = 0
  group_chat_id!: number
  is_leave: boolean = false
  used_capacity: string = ''
  user_id!: number

  constructor(init?: Partial<Pivot>) {
    Object.assign(this, init)
  }
}

export class UserProfileGroupChat {
  avatar: string = ''
  avatar_file_name: string = ''
  user_id!: number
  constructor(init?: Partial<UserProfileGroupChat>) {
    Object.assign(this, init)
  }
}

export class MessageReply {
  messageId!: number
  content: string = ''
  senderId!: number
  groupChatId!: number
  userName: string = ''
  timeSent: string = ''
  day: string = ''
  dayWeek: string = ''
  time: string = ''
  index!: number
  messageSpecial: boolean = false
  isOwnMsg: boolean = false
  isDeletedByAuthor: boolean = false

  constructor(init: any, dayReply: string, dayWeekReply: string, timeReply: string,) {
    this.messageId = init.reply_msg.id
    this.content = init.reply_msg.content
    this.senderId = init.reply_msg.sender.id
    this.groupChatId = init.reply_msg.group_chat_id
    this.userName = init.reply_msg.sender.name
    this.timeSent = init.reply_msg.created_at
    this.index = init.reply_msg.no_in_group_chat
    this.messageSpecial = init.reply_msg.message_special
    this.isOwnMsg = init.reply_msg.is_own_msg
    this.isDeletedByAuthor = init.reply_msg.is_deleted_by_author
    this.day = dayReply
    this.dayWeek = dayWeekReply
    this.time = timeReply
  }
}