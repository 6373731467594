import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'notification' })
class Notification extends VuexModule {
  unreadCount: number = 0
  likeCount: number = 0
  favouriteCount: number = 0
  isChangeNotificationUnread: boolean = false
  triggerFavorite: boolean = false

  @Mutation
  updateUnreadCount(unreadCount: number) {
    this.unreadCount = unreadCount
  }

  @Mutation
  updateLikeCount(likeCount: number) {
    this.likeCount = likeCount
  }

  @Mutation
  updateFavouriteCount(favouriteCount: number) {
    this.favouriteCount = favouriteCount
  }

  @Mutation
  changeNotificationUnread() {
    this.isChangeNotificationUnread = !this.isChangeNotificationUnread
  }

  @Mutation
  handleTriggerFavorite() {
    this.triggerFavorite = !this.triggerFavorite
  }

  @Action
  UPDATE_UNREAD_COUNT(unreadCount: number) {
    this.context.commit('updateUnreadCount', unreadCount)
  }

  @Action
  UPDATE_LIKE_COUNT(likeCount: number) {
    this.context.commit('updateLikeCount', likeCount)
  }

  @Action
  UPDATE_FAVOURITE_COUNT(favouriteCount: number) {
    this.context.commit('updateFavouriteCount', favouriteCount)
  }

  @Action
  CHANGE_NOTIFICATION_UNREAD() {
    this.context.commit('changeNotificationUnread')
  }

  @Action
  TRIGGER_FAVORITE() {
    this.context.commit('handleTriggerFavorite')
  }
}

export default Notification
