




















import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
// import Footer from '@/components/Footer.vue'
import MainContent from '@/components/MainContent.vue'
import NavMenu from '@/components/NavMenu.vue'
import HeaderBeforeLogin from "@/components/HeaderBeforeLogin.vue"
import FooterHome from '@/components/FooterHome.vue'
import FooterMobile from '@/components/FooterMobile.vue'

@Component({
  components: {
    Header,
    // Footer,
    MainContent,
    NavMenu,
    HeaderBeforeLogin,
    FooterHome,
    FooterMobile
  }
})
export default class LayoutCompany extends Vue {}
