var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2 fs-12 bg-light"},[(!_vm.notifications.length)?_c('div',{staticClass:"text-secondary text-center fs-14 py-6"},[_vm._v(" "+_vm._s(_vm.$t('common.message.no_like'))+" ")]):_c('div',{staticClass:"d-block"},_vm._l((_vm.notifications),function(notification,index){return _c('div',{key:index,staticClass:"rounded-1 hover-linear-gradient",class:{ 'bg-read': notification.is_read, 'bg-unread': !notification.is_read },on:{"click":_vm.hideDropdownLike}},[(notification.update_by_user && notification.update_by_user[0].status_company == 'stop' || notification.update_by_user && notification.update_by_user[0].status_company == 'leave')?_c('router-link',{attrs:{"to":''}},[_c('div',{staticClass:"d-flex p-2"},[(notification.like_type !== _vm.eLikeType.CHAT)?_c('figure',{staticClass:"w--9 h--9 flex-fixed mb-0 me-3 rounded-pill overflow-hidden"},_vm._l((notification.like_icon),function(img_path,index){return _c('img',{key:index,staticClass:"img-cover",attrs:{"src":_vm.getLikeNotificationAvatar(
                  notification.like_type,
                  notification.like_icon,
                  index
                ),"alt":""}})}),0):_c('ul',{staticClass:"avatar-group mb-0 flex-fixed me-2"},_vm._l((notification.like_icon),function(item,index){return _c('li',{key:index,staticClass:"avatar-user"},[_c('img',{staticClass:"img-avatar",attrs:{"src":item ? item : _vm.avatarDefault}})])}),0),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"d-block word-break",domProps:{"innerHTML":_vm._s(_vm.handleLikeDescription(notification))}})])])]):_c('router-link',{staticClass:"cursor-pointer",attrs:{"to":_vm.goToNotificationDetail(index)}},[_c('div',{staticClass:"d-flex p-2"},[(notification.like_type !== _vm.eLikeType.CHAT)?_c('figure',{staticClass:"w--9 h--9 flex-fixed mb-0 me-3 rounded-pill overflow-hidden"},_vm._l((notification.like_icon),function(img_path,index){return _c('img',{key:index,staticClass:"img-cover",attrs:{"src":_vm.getLikeNotificationAvatar(
                  notification.like_type,
                  notification.like_icon,
                  index
                ),"alt":""}})}),0):_c('ul',{staticClass:"avatar-group mb-0 flex-fixed me-2"},_vm._l((notification.like_icon),function(item,index){return _c('li',{key:index,staticClass:"avatar-user"},[_c('img',{staticClass:"img-avatar",attrs:{"src":item ? item : _vm.avatarDefault}})])}),0),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"d-block word-break",domProps:{"innerHTML":_vm._s(_vm.handleLikeDescription(notification))}})])])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }