import Const from '../constants'
import axios from 'axios'
import axiosApiInstance from '@/interceptors'

class CalendarService {
  getEventCalendarInWeek(data: any) {
    return axiosApiInstance.get(Const.api_url + 'calendar-events/get-all-calendar-events-by-user-id?startDate=' + data.startDate + '&endDate=' + data.endDate + '&optionDisplay=' + data.optionDisplay)
  }

  getEventCalendarInDay(data: any) {
    return axiosApiInstance.get(Const.api_url + 'calendar-events/get-calendar-events-in-day?userId=' + data.userId + '&dayChoose=' + data.dayChoose)
  }

  getGroupChatById(userId: number) {
    return axiosApiInstance.get(Const.api_url + 'group-chat/show/' + userId)
  }

  createCalendarEvent(data: any) {
    return axiosApiInstance.post(Const.api_url + 'calendar-events', data)
  }

  getCalendarEventById(event_id: any) {
    return axiosApiInstance.get(Const.api_url + 'calendar-events/' + event_id)
  }

  getLoginedUserGroupTags() {
    return axiosApiInstance.get(Const.api_url + 'members/me/group-tags?filter=id;name')
  }

  getMemberHasGroupTagSameUserLogin(data: any) {
    return axiosApiInstance.get(Const.api_url + 'calendar-events/get-all-member?filter=id;name&user_ids='
      + data.userIds + '&search=name:' + data.userName + ';name_kana:' + data.userName + '&limit=' + data.limit + '&page=' + data.page)
  }

  getSettingDeviceOfGroup(data: any) {
    return axiosApiInstance.get(Const.api_url + 'calendar-events/get-all-setting-device?group_name=' + data.group_name + '&page=' + data.page + '&limit=' + data.limit + '&list_setting_device_ids=' + data.list_setting_device_ids)
  }

  getCalendarByWeek(date: string, type: number, group_id: number | null, week_start_date?: number | null) {
    return axiosApiInstance.get(Const.api_url + 'calendar-events/calendar-by-week?date=' + date + '&type=' + type + '&group_id=' + group_id + '&week_start_date=' + week_start_date)
  }

  getEventCalendarUnread(limit: number, page: number) {
    return axiosApiInstance.get(Const.api_url + 'calendar-events/get-unread?page=' + page + '&limit=' + limit)
  }

  markEventCalendarReaded(eventIds: any) {
    return axiosApiInstance.post(Const.api_url + 'calendar-events/mark-read', eventIds)
  }

  markAllEventCalendarReaded() {
    return axiosApiInstance.post(Const.api_url + 'calendar-events/mark-read-all')
  }

  markEventCalendarFavor(eventId: number) {
    return axiosApiInstance.post(Const.api_url + 'calendar-events/mark-favorite/' + eventId)
  }

  getEventCalendarUnreadCount() {
    return axiosApiInstance.get(Const.api_url + 'members/me/unread/count/event-calendar')
  }

  deleteCalendarEventById(eventId: any, deleteType: number, chooseDate: string) {
    return axiosApiInstance.delete(`${Const.api_url}calendar-events/${eventId}?delete_type=${deleteType}&delete_date=${chooseDate}`)
  }

  updateCalendarEventById(event_id: any, data: any) {
    return axiosApiInstance.patch(Const.api_url + 'calendar-events/' + event_id, data)
  }

}

export default new CalendarService
