import { EDeleteFileType, EFileBelong, EFolderItemCountType, EUserStatus } from '.'

export class IdName {
  id!: number
  name: string = ''
  nickname!: string
  name_company!: string
  user_active_status!: number

  constructor(init?: Partial<IdName>) {
    Object.assign(this, init)
  }
}

export class IdTitle {
  id!: number
  title: string = ''

  constructor(init?: Partial<IdTitle>) {
    Object.assign(this, init)
  }
}

export class FileCompact {
  id!: number
  belong_to!: EFileBelong
  name: string = ''
  extension: string = ''
  path: string = ''
  small_path: string = ''
  title: string = ''
  memo: string = ''
  content: string = '' // from home
  updater: IdName = new IdName()
  updated_at: string = ''
  is_read: boolean = false
  group_avatar: string = ''
  user_active_status: EUserStatus = EUserStatus.NORMAL
  status_company: string = ''
  name_company: string = ''

  constructor(init?: Partial<FileCompact>) {
    Object.assign(this, init)
  }
}

export class FileDetail {
  id!: number
  belong_to!: EFileBelong
  extension!: string
  path: string = ''
  small_path: string = ''
  name: string = ''
  size: number = 0
  folder: FileDetailFolder = new FileDetailFolder()
  creator: IdName = new IdName()
  updater: IdName = new IdName()
  last_uploader: IdName = new IdName()
  created_at: string = ''
  updated_at: string = ''
  title: string = ''
  memo: string = ''
  version: number = 1
  topic?: IdTitle = new IdTitle()
  comment_id?: number
  is_in_bin: boolean = false
  creator_active_status: EUserStatus = EUserStatus.NORMAL
  lastUploader_active_status: EUserStatus = EUserStatus.NORMAL
  updater_active_status: EUserStatus = EUserStatus.NORMAL
  name_company_creater: string = ''
  name_company_updater: string = ''

  constructor(init?: Partial<FileDetail>) {
    Object.assign(this, init)
  }
}

export class FileDetailFolder {
  id!: number
  name: string = ''
  is_attach_folder: boolean = false

  constructor(init?: Partial<FileDetailFolder>) {
    Object.assign(this, init)
  }
}

export class FileHistory {
  id!: number
  file_id!: number
  version: number = 1
  uploaded_at: string = ''
  updated_at: string = ''
  name: string = ''
  creator: IdName = new IdName()
  is_can_revert: boolean = false
  creater_active_status: EUserStatus = EUserStatus.NORMAL
  updater_active_status: EUserStatus = EUserStatus.NORMAL
  name_company: string = ''
  status_company: string = ''

  constructor(init?: Partial<FileHistory>) {
    Object.assign(this, init)
  }
}

export interface UpdateInformationParams {
  group_id?: string,
  file_id: string,
  title: string,
  memo: string,
  notify_update: boolean
}

export interface FileGetParams {
  order_by: string,
  sorted_by: string,
  page: number,
  limit: number,
  group_id?: number,
  folder_id?: number,
  unread_only?: string
}

export interface FileToFolderParams {
  group_id: string,
  file_ids: number[],
  folder_id: number
}
export interface DeleteFileParams {
  group_id?: string,
  file_ids: number[],
  delete_type: EDeleteFileType
}
export interface RevertFileHistoryParams {
  group_id?: string,
  file_id: string,
  file_history_id: number
}
export interface RenameFileFolderParams {
  folder_id: number
  new_name: string,
}
export interface FileHistoryGetParams {
  group_id?: string,
  file_id: string,
  page: number,
  limit: number
}
export interface IFolderItemCount {
  type: EFolderItemCountType,
  count: number,
  folder_id?: number
}
