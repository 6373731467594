import Const from '../constants'
import axiosApiInstance from '@/interceptors'

class GroupFolderService {
  getGroupAndFolderOfUser() {
    return axiosApiInstance.get(Const.api_url + 'members/me/user-group-folders')
  }

  getGroupByUderSorted() {
    return axiosApiInstance.get(Const.api_url + 'members/me/get-all-group-folder')
  }

  createFolderByUser(data: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/user-group-folders', data)
  }

  deleteFolderByUser(folder_id: number) {
    return axiosApiInstance.delete(Const.api_url + 'members/me/user-group-folders/' + folder_id)
  }

  editFolderByUser(folder_id: number, data: any) {
    return axiosApiInstance.patch(Const.api_url + 'members/me/user-group-folders/' + folder_id, data)
  }

  moveGroupToFolder(data: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/user-group-folders/move-group-to-folder', data)
  }

  updateNotificationForGroup(data: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/user-group-folders/update-group-notification', data)
  }

  updateOrder(data: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/user-group-folders/update-order', data)
  }

  updateOrderFolder(folderId: number, groupIds: number[]) {
    return axiosApiInstance.post(Const.api_url + 'members/me/user-group-folders/order-group-in-folder/' + folderId, { groupIds: groupIds })
  }
}

export default new GroupFolderService