import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import LayoutHomePage from '@/layouts/LayoutHomePage.vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import LayoutCompactPreview from '@/layouts/LayoutCompactPreview.vue'
import LayoutSetting from '@/layouts/LayoutSetting.vue'
import LayoutChat from '@/layouts/LayoutChat.vue'
import LayoutAuth from '@/layouts/LayoutAuth.vue'
import LayoutAuthRegister from '@/layouts/LayoutAuthRegister.vue'
import LayoutAuthCenter from '@/layouts/LayoutAuthCenter.vue'
import LayoutNotification from '@/layouts/LayoutNotification.vue'
import LayoutHelpCenter from '@/layouts/LayoutHelpCenter.vue'
import LayoutCommonTopic from '@/layouts/Group/LayoutCommonTopic.vue'
import LayoutGroup from '@/layouts/Group/LayoutGroup.vue'
import LayoutGroupMember from '@/layouts/Group/LayoutGroupMember.vue'
import LayoutGroupSetting from '@/layouts/Group/LayoutGroupSetting.vue'
import LayoutCalendar from '@/layouts/LayoutCalendar.vue'
import LayoutBeforeLogin from '@/layouts/LayoutBeforeLogin.vue'
import LayoutCompany from '@/layouts/LayoutCompany.vue'

import i18n from '@/lang'
import { IUserGroupRole } from '@/models'
import updateGroupUserRole from '@/helpers/UpdateGroupUserRole'
import VueMeta from "vue-meta";

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/index.vue'),
    meta: {
      layout: LayoutHomePage,
      title: i18n.t('route_title.home'),
      requiresAuth: true
    }
  },
  {
    path: '/group-information-create',
    name: 'group-setting-information-create',
    component: () => import('@/views/Group/TopPage/Setting/GroupInfoCreatePage.vue'),
    meta: {
      layout: LayoutHomePage,
      title: i18n.t('route_title.group_infomation_create'),
      requiresAuth: true,
      adminAuth: true
    }
  },
  {
    path: '/todo',
    name: 'todo',
    component: () => import('@/views/Todo/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: i18n.t('route_title.todo'),
      requiresAuth: true
    }
  },
  {
    path: '/notification/unread',
    name: 'unread',
    component: () => import('@/views/Notification/UnReadList/index.vue'),
    meta: {
      layout: LayoutNotification,
      title: i18n.t('route_title.unread_notification'),
      requiresAuth: true
    }
  },
  {
    path: '/notification/favourite',
    name: 'favourite',
    component: () => import('@/views/Notification/FavouriteList/index.vue'),
    meta: {
      layout: LayoutNotification,
      title: i18n.t('route_title.favourite_notification'),
      requiresAuth: true
    }
  },
  {
    path: '/notification/like',
    name: 'like',
    component: () => import('@/views/Notification/LikeList/index.vue'),
    meta: {
      layout: LayoutNotification,
      title: i18n.t('route_title.like_notification'),
      requiresAuth: true
    }
  },
  {
    path: '/search-result',
    name: 'search-result',
    component: () => import('@/views/Group/SearchResult/SearchPage.vue'),
    meta: {
      layout: LayoutDefault,
      title: i18n.t('route_title.search_result'),
      requiresAuth: true
    }
  },
  {
    path: '/group/:groupLink/preview',
    name: 'group-preview',
    component: () => import('@/views/Group/Register/GroupPreview.vue'),
    meta: {
      layout: LayoutCompactPreview,
      title: i18n.t('route_title.group_preview'),
      requiresAuth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Group/Register/GroupRegister.vue'),
    meta: {
      layout: LayoutAuthRegister,
      title: i18n.t('route_title.register'),
      requiresAuth: false
    }
  },
  {
    path: '/register-fe',
    name: 'register-fe',
    component: () => import('@/views/Group/Register/GroupRegisterFe.vue'),
    meta: {
      layout: LayoutAuthRegister,
      title: i18n.t('route_title.register'),
      requiresAuth: false
    }
  },
  {
    path: '/group/:groupId/members/register',
    name: 'group-members-register',
    component: () => import('@/views/Group/Register/GroupRegister.vue'),
    meta: {
      layout: LayoutAuth,
      title: i18n.t('route_title.register'),
      requiresAuth: true
    }
  },
  {
    path: '/group',
    name: 'group',
    redirect: '/group/:groupId',
    component: LayoutGroup,
    meta: {
      title: i18n.t('route_title.group_top'),
      requiresAuth: true
    },
    children: [
      {
        path: ':groupId',
        name: 'group-toppage',
        component: () => import('@/views/Group/TopPage/TopPage.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.group_top'),
          requiresAuth: true
        }
      },
      {
        path: ':groupId/new-feed',
        name: 'group-new-feed',
        component: () => import('@/views/Group/TopPage/NewFeed/Layout/NewFeedLayout.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.new_feed_top'),
          requiresAuth: true
        }
      },
      {
        path: ':groupId/topic-create',
        name: 'group-new-feed-topic-create',
        component: () => import('@/views/Group/TopPage/NewFeed/TopicCreate.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.topic_create'),
          requiresAuth: true,
          editTopicAuth: true
        }
      },
      {
        path: ':groupId/topic-update/:topicId',
        name: 'group-new-feed-topic-update',
        component: () => import('@/views/Group/TopPage/NewFeed/TopicCreate.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.topic_update'),
          requiresAuth: true,
        }
      },
      {
        path: ':groupId/topic/:topicId',
        name: 'group-new-feed-topic-detail',
        component: () => import('@/views/Group/TopPage/NewFeed/TopicDetail.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.topic_detail'),
          requiresAuth: true
        }
      },
      {
        path: ':groupId/shared-folder',
        name: 'group-shared-folder',
        component: () => import('@/views/Group/TopPage/SharedFolder/Layout/SharedFolderLayout.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.shared_folder_top'),
          requiresAuth: true
        }
      },
      {
        path: ':groupId/shared-folder/file-create',
        name: 'group-shared-folder-file-create',
        component: () => import('@/views/Group/TopPage/SharedFolder/FileCreate.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.file_create'),
          requiresAuth: true,
          editSharedFolderAuth: true
        }
      },
      {
        path: ':groupId/shared-folder/file/:fileId',
        name: 'group-shared-folder-file-detail',
        component: () => import('@/views/Group/TopPage/SharedFolder/FileDetail.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.file_detail'),
          requiresAuth: true
        }
      },
      {
        path: ':groupId/shared-folder/file/:fileId/file-overwrite',
        name: 'group-shared-folder-file-overwrite',
        component: () => import('@/views/Group/TopPage/SharedFolder/FileOverwrite.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.file_update'),
          requiresAuth: true,
          editSharedFolderAuth: true
        }
      },
      {
        path: ':groupId/shared-folder/file/:fileId/update-information',
        name: 'group-shared-folder-file-update-information',
        component: () => import('@/views/Group/TopPage/SharedFolder/FileUpdateInformation.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.file_update'),
          requiresAuth: true,
          editSharedFolderAuth: true
        }
      },
      {
        path: ':groupId/members',
        name: 'group-members',
        redirect: '/group/:groupId/members/list',
        component: LayoutGroupMember,
        meta: {
          title: i18n.t('route_title.member_list'),
          requiresAuth: true
        },
        children: [
          {
            path: 'list',
            name: 'group-members-list',
            component: () => import('@/views/Group/TopPage/MemberList/MemberList.vue'),
            meta: {
              layout: LayoutCompact,
              title: i18n.t('route_title.member_list'),
              requiresAuth: true
            },
          },
          {
            path: 'qr-code-invite',
            name: 'group-members-qr-code-invite',
            component: () => import('@/views/Group/TopPage/MemberList/QR/QrPage.vue'),
            meta: {
              layout: LayoutCompact,
              title: i18n.t('route_title.qr_code_invite'),
              requiresAuth: true,
              inviteAuth: true
            }
          },
          // {
          //   path: 'qr-code-invite-auth',
          //   name: 'group-members-qr-code-invite-auth',
          //   component: () => import('@/views/Group/TopPage/MemberList/QR/InviteAuth.vue'),
          //   meta: {
          //     layout: LayoutCompact,
          //     title: i18n.t('route_title.qr_code_invite_auth'),
          //     requiresAuth: true,
          //     inviteAuth: true
          //   }
          // },
          {
            path: 'invited',
            name: 'group-members-invited',
            component: () => import('@/views/Group/TopPage/MemberList/Invited.vue'),
            meta: {
              layout: LayoutCompact,
              title: i18n.t('route_title.invited'),
              requiresAuth: true,
              inviteAuth: true
            }
          },
          // {
          //   path: 'join-request',
          //   name: 'group-members-join-request',
          //   component: () => import('@/views/Group/TopPage/MemberList/JoinRequest.vue'),
          //   meta: {
          //     layout: LayoutCompact,
          //     title: i18n.t('route_title.join_request'),
          //     requiresAuth: true,
          //     inviteAuth: true
          //   }
          // },
          {
            path: 'role',
            name: 'group-members-role',
            component: () => import('@/views/Group/TopPage/MemberList/MemberRole.vue'),
            meta: {
              layout: LayoutCompact,
              title: i18n.t('route_title.group_role'),
              requiresAuth: true,
              adminAuth: true
            }
          }
        ]
      },
      {
        path: ':groupId/setting',
        name: 'group-setting',
        redirect: '/group/:groupId/setting/information',
        component: LayoutGroupSetting,
        meta: {
          title: i18n.t('route_title.group_infomation'),
          requiresAuth: true
        },
        children: [
          {
            path: 'information',
            name: 'group-setting-information',
            component: () => import('@/views/Group/TopPage/Setting/GroupInfoPage.vue'),
            meta: {
              layout: LayoutCompact,
              title: i18n.t('route_title.group_infomation'),
              requiresAuth: true
            }
          },
          {
            path: 'information-edit',
            name: 'group-setting-information-edit',
            component: () => import('@/views/Group/TopPage/Setting/GroupInfoEditPage.vue'),
            meta: {
              layout: LayoutCompact,
              title: i18n.t('route_title.group_infomation_edit'),
              requiresAuth: true,
              adminAuth: true
            }
          },
          // {
          //   path: 'equipment',
          //   name: 'group-setting-equipment',
          //   component: () => import('@/views/Group/TopPage/Setting/EquipmentList.vue'),
          //   meta: {
          //     layout: LayoutCompact,
          //     title: i18n.t('route_title.group_equipment'),
          //     requiresAuth: true,
          //     adminAuth: true
          //   }
          // }
        ]
      },
    ]
  },
  {
    path: '/common-topic',
    name: 'common-topic',
    redirect: '/common-topic/topics',
    component: LayoutCommonTopic,
    meta: {
      title: i18n.t('route_title.new_feed_top'),
      requiresAuth: true
    },
    children: [
      {
        path: 'topics',
        name: 'common-topic-new-feed',
        component: () => import('@/views/Group/TopPage/NewFeed/Layout/NewFeedLayout.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.new_feed_top'),
          requiresAuth: true
        }
      },
      {
        path: 'topic/:topicId',
        name: 'common-topic-new-feed-topic-detail',
        component: () => import('@/views/Group/TopPage/NewFeed/TopicDetail.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.topic_detail'),
          requiresAuth: true
        }
      },
      {
        path: 'topic-create',
        name: 'common-topic-new-feed-topic-create',
        component: () => import('@/views/Group/TopPage/NewFeed/TopicCreate.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.topic_create'),
          requiresAuth: true,
          // editTopicAuth: true
        }
      },
      {
        path: 'topic-update/:topicId',
        name: 'common-topic-new-feed-topic-update',
        component: () => import('@/views/Group/TopPage/NewFeed/TopicCreate.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.topic_update'),
          requiresAuth: true,
        }
      },
      {
        path: 'shared-folder',
        name: 'common-topic-shared-folder',
        component: () => import('@/views/Group/TopPage/SharedFolder/Layout/SharedFolderLayout.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.shared_folder_top'),
          requiresAuth: true
        }
      },
      {
        path: 'shared-folder/file/:fileId',
        name: 'common-topic-shared-folder-file-detail',
        component: () => import('@/views/Group/TopPage/SharedFolder/FileDetail.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.file_detail'),
          requiresAuth: true
        }
      },
      {
        path: 'shared-folder/file/:fileId/file-overwrite',
        name: 'common-topic-shared-folder-file-overwrite',
        component: () => import('@/views/Group/TopPage/SharedFolder/FileOverwrite.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.file_update'),
          requiresAuth: true
        }
      },
      {
        path: 'shared-folder/file/:fileId/update-information',
        name: 'common-topic-shared-folder-file-update-information',
        component: () => import('@/views/Group/TopPage/SharedFolder/FileUpdateInformation.vue'),
        meta: {
          layout: LayoutCompact,
          title: i18n.t('route_title.file_update'),
          requiresAuth: true
        }
      },
    ]
  },
  {
    path: '/chat',
    redirect: '/chat/list',
    component: LayoutChat,
    children: [
      {
        path: 'list',
        component: () => import('@/views/Chat/ChatList/index.vue'),
        name: 'chat-list',
        meta: {
          title: i18n.t('route_title.chat_list'),
          requiresAuth: true
        }
      },
      {
        path: 'detail/:groupChatId',
        component: () => import('@/views/Chat/ChatDetail/index.vue'),
        name: 'chat-detail',
        meta: {
          title: i18n.t('route_title.chat_detail'),
          requiresAuth: true
        }
      }
    ],
  },
  // {
  //   path: '/calendar',
  //   name: 'calendar',
  //   redirect: '/calendar/list',
  //   component: LayoutCalendar,
  //   meta: {
  //     title: i18n.t('route_title.calendar'),
  //     requiresAuth: true
  //   },
  //   children: [
  //     {
  //       path: '/calendar/list',
  //       name: 'calendar-list',
  //       component: () => import('@/views/Calendar/index.vue'),
  //       meta: {
  //         title: i18n.t('route_title.calendar'),
  //         requiresAuth: true
  //       },
  //     },
  //     {
  //       path: '/calendar/day',
  //       name: 'calendar-day',
  //       component: () => import('@/views/Calendar/index.vue'),
  //       meta: {
  //         title: i18n.t('route_title.calendar'),
  //         requiresAuth: true
  //       },
  //     },
  //     {
  //       path: '/calendar/week',
  //       name: 'calendar-week',
  //       component: () => import('@/views/Calendar/index.vue'),
  //       meta: {
  //         title: i18n.t('route_title.calendar'),
  //         requiresAuth: true
  //       },
  //     },
  //     {
  //       path: '/calendar/month',
  //       name: 'calendar-month',
  //       component: () => import('@/views/Calendar/index.vue'),
  //       meta: {
  //         title: i18n.t('route_title.calendar'),
  //         requiresAuth: true
  //       },
  //     },
  //     {
  //       path: '/calendar/create',
  //       name: 'calendar-create',
  //       component: () => import('@/views/Calendar/CreateEvent.vue'),
  //       meta: {
  //         title: i18n.t('route_title.create_event'),
  //         requiresAuth: true
  //       }
  //     },
  //     {
  //       path: '/calendar/:id',
  //       name: 'calendar-preview',
  //       component: () => import('@/views/Calendar/EventPreview.vue'),
  //       meta: {
  //         title: i18n.t('route_title.event_preview'),
  //         requiresAuth: true
  //       }
  //     },
  //     {
  //       path: '/calendar/:id/edit',
  //       name: 'calendar-edit',
  //       component: () => import('@/views/Calendar/EditEvent.vue'),
  //       meta: {
  //         title: i18n.t('route_title.edit_event'),
  //         requiresAuth: true
  //       }
  //     },
  //   ]
  // },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/Auth/ForgotPassword.vue'),
    meta: {
      layout: LayoutAuthCenter,
      title: i18n.t('route_title.forgot_password'),
      requiresAuth: false
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/Auth/ResetPassword.vue'),
    meta: {
      layout: LayoutAuthCenter,
      title: i18n.t('route_title.reset_passsword'),
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      layout: LayoutAuth,
      title: i18n.t('route_title.login'),
      requiresAuth: false
    }
  },
  {
    path: '/email/login',
    name: 'email-login',
    component: () => import('@/views/Auth/LoginByEmail.vue'),
    meta: {
      layout: LayoutAuth,
      title: i18n.t('route_title.login'),
      requiresAuth: false
    }
  },
  {
    path: '/employee-without-group',
    name: 'employee-without-group',
    component: () => import('@/views/Auth/EmployeeWithoutGroup.vue'),
    meta: {
      layout: LayoutAuth,
      title: i18n.t('route_title.employee_without_group'),
      requiresAuth: true

    }
  },
  {
    path: '/group-not-found',
    name: 'group-not-found',
    component: () => import('@/views/Auth/GroupNotFound.vue'),
    meta: {
      layout: LayoutAuth,
      title: i18n.t('route_title.group_not_found'),
      requiresAuth: true
    }
  },
  {
    path: '/token-expired',
    name: 'token-expired',
    component: () => import('@/views/Auth/TokenExpired.vue'),
    meta: {
      layout: LayoutAuth,
      title: i18n.t('route_title.token_expired'),
      requiresAuth: false
    }
  },
  // {
  //   path: '/employee-forgot-password',
  //   name: 'employee-forgot-password',
  //   component: () => import('@/views/Auth/EmployeeForgotPassword.vue'),
  //   meta: {
  //     layout: LayoutAuthCenter,
  //     title: i18n.t('route_title.employee_forgot_password'),
  //     requiresAuth: false
  //   }
  // },
  {
    path: '/line-login',
    name: 'line-login',
    component: () => import('@/views/Auth/LineLogin.vue'),
    meta: {
      layout: LayoutAuthCenter,
      title: i18n.t('route_title.line_login'),
      requiresAuth: false
    }
  },
  {
    path: '/setting',
    redirect: '/setting/account-info',
    component: LayoutSetting,
    meta: {
      title: i18n.t('route_title.account_info'),
      requiresAuth: true
    },
    children: [
      {
        path: 'account-info',
        name: 'account-info',
        component: () => import('@/views/Setting/AccountInfo.vue'),
        meta: {
          title: i18n.t('route_title.account_info'),
          requiresAuth: true
        }
      },
      {
        path: 'change-email',
        name: 'change-email',
        component: () => import('@/views/Setting/ChangeEmail.vue'),
        meta: {
          title: i18n.t('route_title.change_email'),
          requiresAuth: true
        }
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: () => import('@/views/Setting/ChangePassword.vue'),
        meta: {
          title: i18n.t('route_title.change_password'),
          requiresAuth: true
        }
      },
      {
        path: 'setting-language',
        name: 'setting-language',
        component: () => import('@/views/Setting/SettingLanguage.vue'),
        meta: {
          title: i18n.t('route_title.setting_language'),
          requiresAuth: true
        }
      },
      {
        path: 'change-info',
        name: 'change-info',
        component: () => import('@/views/Setting/ChangeInfo/ChangeInfo.vue'),
        meta: {
          title: i18n.t('route_title.change_profile'),
          requiresAuth: true
        }
      },
      {
        path: 'setting-display',
        name: 'setting-display',
        component: () => import('@/views/Setting/SettingDisplay.vue'),
        meta: {
          title: i18n.t('route_title.setting_display'),
          requiresAuth: true
        }
      },
      {
        path: 'setting-notify-news',
        name: 'setting-notify-news',
        component: () => import('@/views/Setting/SettingNotifyNews.vue'),
        meta: {
          title: i18n.t('route_title.setting_notify_news'),
          requiresAuth: true
        }
      },
      {
        path: 'setting-order',
        name: 'setting-order',
        component: () => import('@/views/Setting/SettingOrder.vue'),
        meta: {
          title: i18n.t('route_title.display_order_groups'),
          requiresAuth: true
        }
      },
      {
        path: 'setting-theme',
        name: 'setting-theme',
        component: () => import('@/views/Setting/SettingTheme.vue'),
        meta: {
          title: i18n.t('route_title.setting_theme'),
          requiresAuth: true
        }
      },
    ],
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: () => import('@/views/HelpCenter/index.vue'),
    meta: {
      layout: LayoutHelpCenter,
      title: i18n.t('route_title.help_center'),
      requiresAuth: false
    }
  },
  {
    path: '/help-center/:helpCenterId',
    name: 'help-center-detail',
    component: () => import('@/views/HelpCenter/HelpDetail.vue'),
    meta: {
      layout: LayoutHelpCenter,
      title: i18n.t('route_title.help_center_detail'),
      requiresAuth: false
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('@/views/Setting/WithDraw.vue'),
    meta: {
      title: i18n.t('route_title.withdraw'),
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'page-not-found',
    component: () => import('@/views/PageNotFound/index.vue'),
    meta: {
      layout: LayoutDefault,
      title: i18n.t('route_title.page_not_found'),
      requiresAuth: true
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/Auth/Terms.vue'),
    meta: {
      layout: LayoutCompactPreview,
      title: i18n.t('route_title.terms'),
      requiresAuth: false
    }
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/Auth/Policy.vue'),
    meta: {
      layout: LayoutCompactPreview,
      title: i18n.t('route_title.policy'),
      requiresAuth: false
    }
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/views/Verify/index.vue'),
    meta: {
      layout: LayoutCompactPreview,
      title: i18n.t('route_title.verify'),
      requiresAuth: false
    }
  },
  {
    path: '/waiting',
    name: 'waiting',
    component: () => import('@/views/Waiting/index.vue'),
    meta: {
      title: '',
      requiresAuth: false
    }
  },
  // {
  //   path: '/before-login',
  //   name: 'before-login',
  //   component: () => import('@/views/Auth/BeforeLogin.vue'),
  //   meta: {
  //     layout: LayoutBeforeLogin,
  //     title: i18n.t('route_title.before_login'),
  //     requiresAuth: false
  //   }
  // },
  {
    path: '/company',
    redirect: '/company/list',
    component: LayoutCompany,
    meta: {
      title: i18n.t('route_title.company_list'),
      // requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'company-list',
        component: () => import('@/views/Company/index.vue'),
        meta: {
          title: i18n.t('route_title.company_list'),
          // requiresAuth: true
        }
      },
      {
        path: 'detail/:companyId',
        name: 'company-detail',
        component: () => import('@/views/Company/Detail/index.vue'),
        meta: {
          title: i18n.t('route_title.company_detail'),
          // requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/group-target-list/:groupTargetId',
    name: 'group-target-list',
    // redirect: '/group-target/:groupTargetId',
    component: () => import('@/views/GroupTarget/index.vue'),
    meta: {
      layout: LayoutCompany,
      title: i18n.t('route_title.circle_search'),
    }
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: () => import('@/views/ContactUs/ContactUs1.vue'),
    meta: {
      title: i18n.t('route_title.defect_report'),
    }
  },
  {
    path: '/contact-us-2',
    name: 'contact-2',
    component: () => import('@/views/ContactUs/ContactUs2.vue'),
    meta: {
      title: 'お問い合わせ',
    }
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('@/views/ContactUs/Partner.vue'),
    meta: {
      title: 'パートナーになる',
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/ContactUs/About.vue'),
    meta: {
      title: i18n.t('route_title.about'),
    }
  },
  {
    path: '/purpose',
    name: 'purpose',
    component: () => import('@/views/ContactUs/Purpose.vue'),
    meta: {
      title: '我々の目的',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    // savedPosition is only available for popstate navigations.
    if (savedPosition) return savedPosition

    //prevent scroll when router change in same page
    if (to.name === from.name) return {}

    // scroll to anchor by returning the selector
    if (to.hash) {
      let position = { selector: to.hash }

      // specify offset of the element
      // if (to.hash === '#anchor2') {
      //   position.offset = { y: 100 }
      // }
      return position
    }

    // scroll to top by default
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {

  //handle pulldown reload page
  if (to.name === 'chat-detail') {
    document.body.classList.add('body-pulldown-reload')
    setTimeout(function () {
      window.scrollTo(0, 1);
    }, 0);
    // CheckDevice.isMobile() ? (window.document.documentElement as any).requestFullscreen() : '';
  } else {
    document.body.classList.remove('body-pulldown-reload')
    // window.document.fullscreenElement && CheckDevice.isMobile() ? (window.document as any).exitFullscreen() : '';
  }

  document.title = to.meta.title + ' | A-NOTE'
  let token = store.state.auth.token
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({
      path: '/waiting',
      query: { redirect: to.fullPath }
    })
  } else if (to.params.groupId && to.params.groupId !== from.params.groupId) {
    updateGroupUserRole(Number(to.params.groupId)).then(() => {
      groupAuth(to, next);
    })
  } else if (to.params.groupId) {
    groupAuth(to, next);
  } else {
    next()
  }
})

const groupAuth = (to: any, next: any) => {
  let role: IUserGroupRole = store.state.userInfo.user.group_role
  if (!role.role_admin && to.meta.adminAuth) {
    next({ path: `/group/${to.params.groupId}` });
  } else if (!role.role_invite && to.meta.inviteAuth) {
    next({ path: `/group/${to.params.groupId}` });
  } else if (!role.role_edit_shared_folder && to.meta.editSharedFolderAuth) {
    next({ path: `/group/${to.params.groupId}` });
  } else if (!role.role_edit_topic && to.meta.editTopicAuth) {
    next({ path: `/group/${to.params.groupId}` });
  } else {
    next();
  }
}

export default router
