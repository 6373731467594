















































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {

    }
})


export default class FooterHome extends Vue {
  private yearNow: any = ''
  private currentUrl: any = window.location.origin
  private privacy_policy_url = process.env.VUE_APP_PRIVACY_POLICY_URL

  created() {
    const today = new Date();
    this.yearNow = today.getFullYear()
    this.currentUrl = window.location.origin
  }
}
