import store from '@/store'

export default function authHeader() {
  let token = store.state.auth.token

  if (token) {
    return { Authorization: 'Bearer ' + token }
  } else {
    return {}
  }
}
