import Vue from 'vue'
import VueI18n from 'vue-i18n'

// @ts-ignore
import ValidationJaLocale from '@/validations/lang/ja.json'
// @ts-ignore
import ValidationEnLocale from '@/validations/lang/en.json'
// User defined lang
import enLocale from './en'
import jaLocale from './ja'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...{
      fields: ValidationEnLocale.fields,
      validation: ValidationEnLocale.messages
    }
  },
  ja: {
    ...jaLocale,
    ...{
      fields: ValidationJaLocale.fields,
      validation: ValidationJaLocale.messages
    }
  }
}

export const getLocale = () => {
  const language = JSON.parse(String(sessionStorage.getItem('vuex')))?.setting?.setting?.setting_language?.code
  return language ? language : 'ja'
}

const i18n = new VueI18n({
  locale: getLocale(),
  messages
})

export default i18n
