






import { Component, Prop, Vue } from 'vue-property-decorator'
import NavHeader from '@/components/NavHeader.vue'

@Component({
  components: {
    NavHeader
  }
})
export default class Header extends Vue {
  @Prop() noSticky!: boolean
  @Prop({ default: 'modal-search-header' }) private modalSearchHeaderId?: string
}
