
















































































import { Prop, Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import PickMenu from '@/store/modules/PickMenu'
import { PickMenuItem } from '@/models'

const CommonModule = getModule(PickMenu, store)

@Component
export default class PickMenuList extends Vue {
  @Prop() private listPickMenu!: PickMenuItem[]

  clickEvent(func: any) {
    if (func) {
      func()
      CommonModule.CLOSE_ALL()
    } else {
      CommonModule.CLOSE_ALL()
    }
  }
}
