















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ModalConfirm extends Vue {
  handleRedirectStatus() {
    this.$bvModal.hide('modal-api-status')
    this.$router.push({ name: 'home' })
    setTimeout(() => {
      window.location.reload()
    }, 300)
  }
}
