





























import { Component, Vue } from 'vue-property-decorator'
import ManageMemberGroupChat from '@/views/Chat/ManageMemberGroupChat/index.vue'
import GroupChatService from '@/services/GroupChatService'

@Component({
  components: {
    ManageMemberGroupChat
  }
})
export default class NavMemberGroupChatRecent extends Vue {}
