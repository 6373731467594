




import { Component, Vue } from 'vue-property-decorator'
import NavHeaderBeforeLogin from '@/components/NavHeaderBeforeLogin.vue'

@Component({
  components: {
    NavHeaderBeforeLogin
  }
})
export default class HeaderBeforeLogin extends Vue {

}
