var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('div',{staticClass:"position-relative h--15 h-xl--25 w-100 transition"},[_c('div',{staticClass:"position-absolute h--15 h-xl--25 w-100 z-1",style:(_vm.banner_header_background)}),_c('div',{staticClass:"d-flex align-items-center h-100"},[_vm._m(0),_c('div',{staticClass:"flex-center mt-6 pt-xl-12 cursor-pointer"},[_c('span',{staticClass:"fw-bold fs-20 fs-xl-25 pb-1 pb-xl-5 px-2 text-line-clamp-1 z-2 text-shadow-light-012"},[_vm._v(" "+_vm._s(_vm.$t('groups.common_topic'))+" ")])])])]),_c('ul',{staticClass:"tabs list-unstyled d-flex bg-secondary-deep mb-0 position-relative z-10"},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:tab.id,staticClass:"flex-1 flex-xl-none w-xl--50 border-end border-secondary",class:{
        'border-end-0 border-end-xl': index === _vm.tabs.length - 1
      }},[_c('router-link',{staticClass:"flex-center text-centerd fs-11 h-100 py-3 fs-xl-14",class:{
          'bg-secondary-less transition-fast': _vm.$route.name
            ? _vm.$route.name.includes(tab.routerName)
            : false,
          'text-white hover-bg-secondary-less-3': _vm.$route.name
            ? !_vm.$route.name.includes(tab.routerName)
            : false
        },attrs:{"to":tab.routerLink}},[_vm._v(" "+_vm._s(tab.displayName)+" ")])],1)}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-center rounded-pill img-cover cursor-pointer bg-light h--13 w--13 h-xl--20 w-xl--20 ms-3 z-2"},[_c('img',{staticClass:"rounded-pill img-cover h--13 w--13 h-xl--20 w-xl--20",attrs:{"src":require("@/assets/images/logo-cs-1.png"),"alt":"logo-cs"}})])}]

export { render, staticRenderFns }