



























import { Component, Vue, Watch } from 'vue-property-decorator'
import { ITabModel } from '@/models'
import GroupService from '@/services/GroupService'

@Component
export default class GroupMainNav extends Vue {
  private tabs: ITabModel[] = []
  private isDefault: any =  ''

  created() {
    // this.checkGroupDefault()
    this.initTab()
  }

  @Watch('$route.params.groupId')
  initTab() {
    this.tabs = [
      {
        id: 0,
        displayName: String(this.$t('groups.new_feed.title')),
        routerName: 'group-new-feed',
        routerLink: `/group/${this.$route.params.groupId}/new-feed`
      },
      {
        id: 1,
        displayName: String(this.$t('groups.shared_folder.title')),
        routerName: 'group-shared-folder',
        routerLink: `/group/${this.$route.params.groupId}/shared-folder`
      },
      {
        id: 2,
        displayName: String(this.$t('groups.member_list.title')),
        routerName: 'group-members',
        routerLink: `/group/${this.$route.params.groupId}/members`
      },
      {
        id: 3,
        displayName: String(this.$t('groups.setting.title')),
        routerName: 'group-setting',
        routerLink: `/group/${this.$route.params.groupId}/setting`
      }
    ]
  }

  checkGroupDefault() {
    GroupService.checkGroupDefault(this.$route.params.groupId)
      .then(res => {
        if (res.status == 200) {
          this.isDefault = res.data
          if (this.isDefault == 1) {
            this.tabs.splice(2, 1)
          }
          //console.log(this.tabs.splice(2, 1));
          
          // this.tabs.splice(2, 1);
        }
      })
  }
}
