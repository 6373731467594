






























import FooterMobile from '@/components/FooterMobile.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { FooterMobile } })
export default class LayoutAuthCenter extends Vue {
  goToLogin() {
    this.$router.push({ name: 'before-login' })
  }
}
