import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'commonTopic' })
class CommonTopic extends VuexModule {
  common_topic_unread_count: number = 0
  reloadUnread: boolean = false

  @Mutation
  setNewCommonTopicCount(count: number) {
    this.common_topic_unread_count = count
  }

  @Action
  SET_NEW_COMMON_TOPIC_COUNT(count: number) {
    this.context.commit('setNewCommonTopicCount', count)
  }

  @Mutation
  setRealoadUnread(reaload: boolean) {
    this.reloadUnread = reaload
  }

  @Action
  SET_RELOAD_UNREAD(reaload: boolean) {
    this.context.commit('setRealoadUnread', reaload)
  }
}

export default CommonTopic
