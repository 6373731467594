
















import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
// import Footer from '@/components/Footer.vue'
import FooterHome from '@/components/FooterHome.vue'
import Banner from '@/components/Banner.vue'
import NavMenu from '@/components/NavMenu.vue'
import FooterMobile from '@/components/FooterMobile.vue'

@Component({
  components: {
    Header,
    Banner,
    NavMenu,
    FooterHome,
    FooterMobile
  }
})
export default class LayoutDefault extends Vue {}
