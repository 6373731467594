import moment from 'moment'
import i18n from '@/lang/index'

class FormatDate {
  private days = [
    String(i18n.t('common.date_pick.sun')),
    String(i18n.t('common.date_pick.mon')),
    String(i18n.t('common.date_pick.tue')),
    String(i18n.t('common.date_pick.wed')),
    String(i18n.t('common.date_pick.thu')),
    String(i18n.t('common.date_pick.fri')),
    String(i18n.t('common.date_pick.sat'))
  ]

  getDayOfWeek(index: number) {
    return this.days[index]
  }

  formatDate(date: string) {
    return this.format(date, false)
  }

  formatDateWithDays(date: string) {
    return this.format(date, true)
  }

  formatDateWithoutDays(date: string) {
    const momentDate = moment(date)
    const yyyymmdd = i18n.t('common.date_format.yyyy_mm_dd') as string
    return momentDate.format(yyyymmdd)
  }

  formatCompact(date: string) {
    const momentDate = moment(date)
    const mmdd_compact = i18n.t('common.date_format.mm_dd_compact') as string
    return moment(date).format(mmdd_compact) + `(${this.days[momentDate.day()]})`
  }

  format(date: string, haveDay: boolean) {
    const momentDate = moment(date)
    const mmdd = haveDay ? i18n.t('common.date_format.mm_dd') as string : i18n.t('common.date_format.mm_dd') as string
    const yyyymmdd = haveDay ? i18n.t('common.date_format.yyyy_mm_dd') as string + ` (${this.days[momentDate.day()]})` : i18n.t('common.date_format.yyyy_mm_dd') as string

    if (momentDate.isSame(moment(), 'day')) {
      return moment(date).format('HH:mm')
    }
    if (momentDate.year() === moment().year()) {
      return moment(date).format(mmdd) + ` (${this.days[momentDate.day()]})`
    }
    return moment(date).format(yyyymmdd)
  }

  formatEnWitdJaDay(date: string) {
    const momentDate = moment(date)
    const yyyymmdd = `YYYY/MM/DD (${this.days[momentDate.day()]}) HH:mm`
    return moment(date).format(yyyymmdd)
  }
}

export default new FormatDate()
