import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import i18n from '@/lang/index';
import { EhumbNailsDisplay, EListSort, ETopageSort } from '@/models/Setting/Enum';
@Module({ namespaced: true, name: 'setting' })
class Setting extends VuexModule {
  setting_default: any = {
    setting_language: {
      id: 1,
      name: i18n.t('lang.ja'),
      code: 'ja'
    },

    setting_display: {
      number_item_lists: 6,
      number_items_list_comment: 10,
      display_image_video: EhumbNailsDisplay.DISPLAY_THUMBNAILS,
      first_day_of_week: 1
    },

    setting_notify_news: {
      regular_stop_notify_start: null,
      regular_stop_notify_end: null,
      first_email_regular_option: 1,
      second_email_regular: '',
      second_email_regular_option: 0,
      third_email_regular: '',
      third_email_regular_option: 0,
      email_notify_time: 9,
      schedule_email_option: 1,
      notify_interval: 24,
      first_email_emergency_option: 1,
      second_email_emergency: '',
      second_email_emergency_option: 0,
      third_email_emergency: '',
      third_email_emergency_option: 0,
      emergency_stop_notify_start: null,
      emergency_stop_notify_end: null,
    },

    setting_themes: {
      theme_type: 0,
      header_file_name: '',
      theme: {
        theme_name: 'theme default',
        nav_menu_bg_color: '#FFF',
        nav_menu_bg_img: null,
        banner_header_bg_color: null,
        banner_header_bg_img: '',
        main_bg_color: '#FFF',
        main_bg_img: null,
        title_color: '#FFF',
        title_background: '#FFF'
      },
    }
  }

  theme_default: any = { ...this.setting_default.setting_themes.theme }

  user_sort_default: any = {
    toppage_sort_new: ETopageSort.SORT_BY_NEW,
    group_topic: EListSort.UPDATE_DESC,
    group_shared_folder: EListSort.UPDATE_DESC,
    common_topic: EListSort.UPDATE_DESC,
    common_shared_folder: EListSort.UPDATE_DESC,
    chat_list: EListSort.UPDATE_DESC
  }

  setting: any = { ...this.setting_default }
  user_sort: any = { ...this.user_sort_default }
  viewPointDesktop: boolean = false

  // Hanlde User Setting
  @Mutation
  handleUserSetting(new_setting: any) {
    if (new_setting.length !== 0) {
      this.setting = new_setting
    } else {
      return this.setting
    }
    if (new_setting.setting_language.name === "英語") {
      this.setting.setting_language.name = "English"
    }
    if (new_setting.setting_language.name === "Japanese") {
      this.setting.setting_language.name = "日本語"

    }
  }

  @Action({ rawError: true })
  HANDLE_USER_SETTING(new_setting: any) {
    this.context.commit('handleUserSetting', new_setting)
  }

  @Action({ rawError: true })
  RESET_DEFAULT() {
    this.context.commit('resetDefault')
  }

  @Mutation
  resetDefault() {
    this.setting = { ...this.setting_default }
    this.user_sort = { ...this.user_sort_default }
  }

  // Setting Theme
  @Mutation
  changeTheme(new_theme: any) {
    this.setting.setting_themes.theme = new_theme
  }

  @Mutation
  changeHeader(new_header: any) {
    this.setting.setting_themes.theme.banner_header_bg_img = new_header
  }

  @Mutation
  changeHeaderFileName(new_name: any) {
    this.setting.setting_themes.header_file_name = new_name
  }

  @Mutation
  changeTitleColor(new_title_color: any) {
    this.setting.setting_themes.theme.title_color = new_title_color
  }

  @Mutation
  changeTitleBackground(new_title_background: any) {
    this.setting.setting_themes.theme.title_background = new_title_background
  }

  @Mutation
  changeThemeType(new_type: any) {
    this.setting.setting_themes.theme_type = new_type
  }

  @Action({ rawError: true })
  CHANGE_THEME(new_theme: any) {
    this.context.commit('changeTheme', new_theme)
  }

  @Action({ rawError: true })
  CHANGE_HEADER(new_header: any) {
    this.context.commit('changeHeader', new_header)
  }

  @Action({ rawError: true })
  CHANGE_HEADER_FILE_NAME(new_name: any) {
    this.context.commit('changeHeaderFileName', new_name)
  }

  @Action({ rawError: true })
  CHANGE_TITLE_COLOR(new_title_color: any) {
    this.context.commit('changeTitleColor', new_title_color)
  }

  @Action({ rawError: true })
  CHANGE_TITLE_BACKRGROUND(new_title_background: any) {
    this.context.commit('changeTitleBackground', new_title_background)
  }

  @Action({ rawError: true })
  CHANGE_THEME_TYPE(new_type: any) {
    this.context.commit('changeThemeType', new_type)
  }


  // Setting_Language
  @Mutation
  changeLanguage(new_language: any) {
    this.setting.setting_language = new_language
  }

  @Action({ rawError: true })
  CHANGE_LANGUAGE(new_language: any) {
    this.context.commit('changeLanguage', new_language)
  }

  //Setting_Display
  @Mutation
  changeDisplay(new_setting_display: any) {
    this.setting.setting_display = new_setting_display
  }

  @Action({ rawError: true })
  CHANGE_DISPLAY(new_setting_display: any) {
    this.context.commit('changeDisplay', new_setting_display)
  }

  //Setting_Notify_News
  @Mutation
  changeNotifyNews(new_notify_news: any) {
    this.setting.setting_notify_news = new_notify_news
  }

  @Action({ rawError: true })
  CHANGE_NOTIFY_NEWS(new_notify_news: any) {
    this.context.commit('changeNotifyNews', new_notify_news)
  }

  /**
   * Setting user sort
   */
  //toppage
  @Mutation
  changeSortToppage(sort: ETopageSort) {
    this.user_sort.toppage_sort_new = sort
  }

  @Action({ rawError: true })
  CHANGE_SORT_TOPPAGE(sort: ETopageSort) {
    this.context.commit('changeSortToppage', sort)
  }

  //group topic
  @Mutation
  changeSortGroupTopic(sort: EListSort) {
    this.user_sort.group_topic = sort
  }

  @Action({ rawError: true })
  CHANGE_SORT_GROUP_TOPIC(sort: EListSort) {
    this.context.commit('changeSortGroupTopic', sort)
  }

  //group shared folder
  @Mutation
  changeSortGroupSharedFolder(sort: EListSort) {
    this.user_sort.group_shared_folder = sort
  }

  @Action({ rawError: true })
  CHANGE_SORT_GROUP_SHARED_FOLDER(sort: EListSort) {
    this.context.commit('changeSortGroupSharedFolder', sort)
  }

  //common topic
  @Mutation
  changeSortCommonTopic(sort: EListSort) {
    this.user_sort.common_topic = sort
  }

  @Action({ rawError: true })
  CHANGE_SORT_COMMON_TOPIC(sort: EListSort) {
    this.context.commit('changeSortCommonTopic', sort)
  }

  //common shared folder
  @Mutation
  changeSortCommonSharedFolder(sort: EListSort) {
    this.user_sort.common_shared_folder = sort
  }

  @Action({ rawError: true })
  CHANGE_SORT_COMMON_SHARED_FOLDER(sort: EListSort) {
    this.context.commit('changeSortCommonSharedFolder', sort)
  }

  //chat list
  @Mutation
  changeSortChatList(sort: EListSort) {
    this.user_sort.chat_list = sort
  }

  @Action({ rawError: true })
  CHANGE_SORT_CHAT_LIST(sort: EListSort) {
    this.context.commit('changeSortChatList', sort)
  }

  /**
   * View point
   */
  @Mutation
  toggleViewPoint(bool: boolean) {
    this.viewPointDesktop = bool
  }

  @Action({ rawError: true })
  TOGGLE_VIEW_POINT(bool: boolean) {
    this.context.commit('toggleViewPoint', bool)
  }
}
export default Setting

