

































import { Prop, Component, Vue } from 'vue-property-decorator'

@Component
export default class ModalError extends Vue {
  @Prop({ default: 'modal-error' }) private id!: string
  @Prop() private errorMess!: string
  @Prop({ default: 3 }) private closeSeconds!: number
  @Prop({ default: true }) private autoClose!: boolean

  hideModal() {
    this.$bvModal.hide(this.id)
    setTimeout(() => {
      this.$emit('close')
      clearTimeout()
    }, 300)
  }

  hanldeAutoClose() {
    if (this.autoClose) {
      setTimeout(() => {
        this.$bvModal.hide(this.id)
        clearTimeout()
        this.$router.push({ name: 'home' })
      }, this.closeSeconds * 1000)
    }
  }
}
