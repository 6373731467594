//@ts-ignore
import _ from 'lodash'
class PreviewText {

  covertToPreviewText(text: string, max: number = 50) {
    const preText = _.truncate(text, {
      length: max,
      separator: ''
    })
    if (text?.length > max) {
      return {
        isLong: true,
        text: preText
      }
    }
    return {
      isLong: false,
      text: preText
    }
  }
}

export default new PreviewText()
