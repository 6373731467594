import { Vue } from 'vue-property-decorator'

export default class DatePickerLang {
  public days: string[];
  public months: string[];

  constructor(vue: Vue) {
    this.days = [
      String(vue.$t('common.date_pick.sun')),
      String(vue.$t('common.date_pick.mon')),
      String(vue.$t('common.date_pick.tue')),
      String(vue.$t('common.date_pick.wed')),
      String(vue.$t('common.date_pick.thu')),
      String(vue.$t('common.date_pick.fri')),
      String(vue.$t('common.date_pick.sat')),
    ]

    this.months = [
      String(vue.$t('common.date_pick.january')),
      String(vue.$t('common.date_pick.february')),
      String(vue.$t('common.date_pick.march')),
      String(vue.$t('common.date_pick.april')),
      String(vue.$t('common.date_pick.may')),
      String(vue.$t('common.date_pick.june')),
      String(vue.$t('common.date_pick.july')),
      String(vue.$t('common.date_pick.august')),
      String(vue.$t('common.date_pick.september')),
      String(vue.$t('common.date_pick.october')),
      String(vue.$t('common.date_pick.november')),
      String(vue.$t('common.date_pick.december'))
    ]
  }
}
