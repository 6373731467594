
import { EAvatarType, ENameColor, EUserStatus } from './Enum'
import { IdName, keepFirstElementOnly } from '@/models'
//@ts-ignore
import _ from 'lodash'
export class GroupDetail {
  id!: number;
  created_at: string = '1900-01-01';
  name: string = '';
  group_tags: any[] = [];
  icon_image: EAvatarType = EAvatarType.DEFAULT;
  icon_image_path: string = '';
  icon_image_small_path: string = '';
  header_image: string = '';
  letter_color: ENameColor = ENameColor.WHITE;
  notice: string = '';
  auto_delete_day: number = 30;
  group_link: string = '';
  header_file_name: string = '';
  icon_file_name: string = ''
  max_img_size_per_attachment: number = 25

  constructor(init?: Partial<GroupDetail>) {
    Object.assign(this, init)
    !this.notice ? this.notice = '' : ''
  }
}
export class GroupStorage {
  id!: number;
  storage_capacity: number = 10;
  storage_used: number = 0;
  type: string = 'GB';
  percent: number = 0;
  constructor(init?: Partial<GroupStorage>) {
    Object.assign(this, init)
    this.percent = Number(((this.storage_used / this.storage_capacity) * 100).toFixed(2))
  }
}

export class GroupMenuCompact {
  id!: number;
  name: string = '';
  unread_count: number = 0;

  constructor(init?: Partial<GroupMenuCompact>) {
    Object.assign(this, init)
  }
}
export class GroupOrder {
  id!: number
  can_notify: boolean = false
  icon_image_path: string = ''
  icon_image_small_path: string = ''
  name: string = ''
  unread_count: number = 0
  folder: string = ''
  status_company: string = ''

  constructor(init?: Partial<GroupOrder>) {
    Object.assign(this, init)
  }
}

export class GroupTagName {
  id!: number;
  name: string = '';
  constructor(init?: Partial<GroupTagName>) {
    Object.assign(this, init)
  }
}

export class Equipment {
  id!: number;
  name: string = '';
  memo: string = '';

  constructor(init?: Partial<Equipment>) {
    Object.assign(this, init)
  }
}

/**
 * This class have been use in multiple space
 * 
 * @Check before use
 */
export class TopicFileCompact {
  //general
  id!: number
  title: string = ''
  user_name: string = '' //name of updater
  updated_date: string = ''
  is_read: boolean = false
  is_topic: boolean = false
  object_type?: ETopicFileHomeType
  group_avatar: string = ''
  emergency_contact: boolean = false
  user_active_status: EUserStatus = EUserStatus.NORMAL
  nickname: string = ''
  name_company: string = ''
  status_company: string = ''
  status_company_create: string = ''

  //file
  extension: string = ''
  path: string = '' // file path
  memo: string = ''

  //topic
  update_topic: boolean = false
  content: string = ''
  avatar: string = '' // updater avatar

  //view modal - file
  name: string = ''
  updated_at = ''
  updater: IdName = new IdName()

  //view modal - topic
  updater_avatar: string = ''
  update_at: string = ''

  constructor(init?: Partial<TopicFileCompact>, groupAvatar?: string) {
    Object.assign(this, init)

    groupAvatar ? this.group_avatar = groupAvatar : ''

    this.updated_at = this.updated_date
    this.updater = new IdName({ name: this.user_name })
    this.updater_avatar = this.avatar
    this.update_at = this.updated_date

    if (!this.extension && this.name) {
      const fileNameSplit: string[] = this.name.split('.')
      this.extension = fileNameSplit[fileNameSplit.length - 1]
    }

    switch (this.object_type) {
      case ETopicFileHomeType.TOPIC:
        this.is_topic = true
        this.update_topic = true
        break
      case ETopicFileHomeType.TOPIC_COMMENT:
        this.is_topic = true
        this.update_topic = false
        break
      case ETopicFileHomeType.SHARED_FOLDER:
        this.is_topic = false
        break
    }

    if (this.is_topic && this.content) {
      this.content = '<div><span>' + this.content + '</span></div>'
      let doc = new DOMParser().parseFromString(this.content, 'text/html')
      let wrapper: any = doc.querySelector('div')
      let el: any = wrapper.firstChild
      if (el) {
        el.innerText = _.truncate(el.innerText, {
          'length': 50,
          'separator': ' '
        });
        el?.classList ? el?.classList.add("text-line-clamp-1") : ''
        this.content = keepFirstElementOnly(wrapper, el, wrapper.childElementCount > 1)
      }
    }
  }
}

export enum ETopicFileHomeType {
  TOPIC = 1,
  TOPIC_COMMENT = 2,
  SHARED_FOLDER = 3
}

export interface AddEditEquipmentParams {
  group_id: number,
  equipment_id?: number,
  name?: string,
  memo?: string
}
export interface IUserGroupRole {
  group_id: number,
  role_admin: boolean,
  role_invite: boolean,
  role_edit_shared_folder: boolean,
  role_edit_topic: boolean,
}

export interface TopicFilePrams {
  group_id: string,
  order_by: string,
  sorted_by: string,
  page: number,
  limit: number,
  unread_only?: string
}
