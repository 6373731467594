export enum ETimeRange {
  _1_WEEK,
  _1_MONTH,
  _3_MONTH,
  _6_MONTH,
  _1_YEAR,
  CUSTOM
}

export enum ETargetSeach {
  TOPIC = 1,
  CALENDAR = 2,
  CHAT = 3,
  SHARED_FOLDER = 4
}

export enum ETargetData {
  TITLE = 1,
  CONTENT = 2,
  COMMENT = 3,
  UPLOADER = 4,
  SENT_TO = 5
}

export interface SearchParams {
  target_search: string,
  target_data: string[],
  target_data_string: string,
  target_group: string,
  target_group_name: string,
  keyword_search: string,
  time_range: ETimeRange,
  time_start: string,
  time_end: string
}

export class SearchResult {
  belong_to!: ETargetSeach
  id!: number
  group_icon: string = ''
  content: string = ''
  title: string = ''
  updated_date: string = ''
  updater!: IUpdater
  updater_id!: number
  last_updater_id!: number
  name_company: string = ''
  status_company: string = ''

  //for topic
  emergency_contact: boolean = false
  user_comment_topics: ISearchComment[] = []
  is_topic: boolean = false
  topic_id!: number
  cmt_id!: number

  //for file
  path: string = ''

  //for chat
  name: string = ''
  sender_id!: number
  is_direct_chat!: boolean // only_user
  group_chat_id!: number
  group_chat_avatar: string[] = []
}

export interface ISearchComment {
  id: number
  topic_id: number
  content: string
  updated_at: string
}

export interface IUpdater {
  id: number
  name: string
  user_profile: IUserProfile
  nickname: string
}

export interface IUserProfile {
  id: number
  user_id: number
  avatar: string
}