
















import { Component, Vue } from 'vue-property-decorator'
import CommonTopicHeader from '@/components/Group/CommonTopicHeader.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'

@Component({ components: { CommonTopicHeader, NavMenuBar } })
export default class LayoutCommonTopic extends Vue {}
