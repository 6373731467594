























































































































































































































import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import { groupchatRef } from "@/firebase";
import { GroupChatModel, IdName, UserModel } from "@/models";
import GroupChatService from "@/services/GroupChatService";
import moment from "moment";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import Chat from "@/store/modules/Chat";
import GroupService from "@/services/GroupService";

const ChatModule = getModule(Chat, store);

@Component
export default class ManageMemberGroupChat extends Vue {
  @Prop({ default: false }) private disableRemoveMember!: boolean;
  @Prop() private groupSelected?: IdName;
  @Model("change", { type: Number, default: -1 })
  groupItemSelected!: number;
  private groupChatSelected: GroupChatModel = new GroupChatModel();
  private currentUser: UserModel = new UserModel();
  private inputSearchMember: string = "";
  private lstMemberSearch: any[] = [];
  private lstMemberChoose: any[] = [];
  private limit: number = 50;
  private page: number = 1;
  private scrollLstMemberSearch: any;
  private disableCreate: boolean = false;
  private scrolledToBottom: boolean = false;
  private isInviteMember: boolean = false;
  private isLoadingSearchUser: boolean = false;
  private showMsgSearchUserChat: boolean = true;
  private groups: IdName[] = [];
  private selectedGroup: IdName = new IdName();
  private planChatModal: any[] = [];

  get userLogin() {
    return this.$store.state.userInfo.user;
  }

  private setUserLogin(user: any) {
    this.$set(this.currentUser, "userId", user.info.id);
    this.$set(this.currentUser, "userName", user.info.name);
    this.$set(this.currentUser, "avatar", user.profile.avatar);
  }

  created() {
    this.setUserLogin(this.userLogin);
    if (this.groupItemSelected !== -1) {
      this.isInviteMember = true;
      this.getInfoGroupChat();
    } else {
      this.isInviteMember = false;
    }
    this.searchMember();
    this.getGroups();
    this.getPlan();
  }

  getPlan() {
    GroupChatService.getPlanCompany()
      .then((res) => {
        if (res.status === 200) {
          this.planChatModal = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  clickShowModal(number: number) {
  
    if (number == 4 || number == 5) {
      this.$bvModal.show("modal-show-err-email");
    } else {this.$bvModal.show('modal-confirm')}
  }

  @Watch("selectedGroup.id")
  watchSelectedGroup() {
    this.lstMemberChoose = [];
    this.searchMember();
  }

  getGroups() {
    if (this.groupSelected) {
      this.groups = [this.groupSelected];
      this.selectedGroup = this.groupSelected;
      return;
    }
    GroupService.getGroupOrder(1, 99999, "1")
      .then((res) => {
        if (res.status === 200) {
          this.groups = res.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSelectGroup(group: IdName) {
    this.selectedGroup = group;
  }

  private async getInfoGroupChat() {
    if (this.groupItemSelected) {
      await GroupChatService.getGroupChatById(this.groupItemSelected)
        .then((res: any) => {
          if (res.status === 200) {
            if (res && res.data) {
              this.setInfoGroupChat(res.data);
            }
          }
        })
        .catch((err: any) => {});
    }
  }

  private setInfoGroupChat(data: any) {
    let timeSent = "";
    if (data.updated_at) {
      if (
        moment(data.updated_at).format("DD/MM/YYYY") ===
        moment().format("DD/MM/YYYY")
      ) {
        timeSent = moment(data.updated_at).format("HH:mm");
      } else {
        timeSent = `${moment(data.updated_at).format("DD/MM")}（${moment(
          data.updated_at
        ).format("dddd")}）`;
      }
    }
    // favorite group chat
    let favoriteGroupChat: boolean = false;
    let lstUser: any[] = [];
    if (data.user_group_chat.length > 0) {
      data.user_group_chat.forEach((userGroupChat: any) => {
        if (this.currentUser.userId === userGroupChat.id) {
          favoriteGroupChat = userGroupChat.pivot.favorite === 1 ? true : false;
        } else {
          // set lst user id choose
          let userNew: any = {};
          userNew.userId = userGroupChat.id;
          userNew.userName = userGroupChat.name;
          userNew.choose = true;
          userNew.disable = this.disableRemoveMember;
          lstUser.push(userNew);
        }
      });
    }
    // set to the screen
    this.lstMemberChoose = lstUser;

    // set group chat name
    let groupChatName = "<div>";
    if (data.user_group_chat.length > 0) {
      data.user_group_chat.forEach((user: any) => {
        let userName = "";
        userName = `<span>${user.name}</span><span class="fwb">${this.$t(
          "common.suffix.san"
        )}</span>, `;
        groupChatName += userName;
      });
      groupChatName = groupChatName.slice(0, -2);
      groupChatName += "</div>";
    }

    this.groupChatSelected = new GroupChatModel(
      data,
      groupChatName,
      favoriteGroupChat,
      timeSent
    );
  }

  checkScroll() {
    let scrollTop = (this.$refs.lstMemberSearch as any).scrollTop;
    let scrollHeight = (this.$refs.lstMemberSearch as any).scrollHeight;
    if (scrollHeight - scrollTop <= 160) {
      this.limit = this.limit + 50;
      this.searchMember();
    }
  }

  private async searchMember() {
    if (!this.selectedGroup.id) {
      this.lstMemberSearch = [];
      return;
    }
    let lstMemberIdChoose: any[] = [];
    lstMemberIdChoose.push(this.currentUser.userId);
    if (this.lstMemberChoose.length > 0) {
      this.lstMemberChoose.forEach((user: any) => {
        if (!lstMemberIdChoose.includes(user.userId)) {
          lstMemberIdChoose.push(user.userId);
        }
      });
    }
    // get api search all member by search input and user id not in  lstMemberIdChosse
    let userId: string = "";
    if (lstMemberIdChoose.length > 0) {
      lstMemberIdChoose.forEach((item: any) => {
        userId += `${item},`;
      });
      userId = userId.slice(0, -1);
    }

    let dataSearchMember: any = {
      userIds: userId,
      userName: encodeURIComponent(this.inputSearchMember.toString().trim()),
      limit: this.limit,
      page: 1,
      groupId: this.selectedGroup.id,
    };

    if (this.disableRemoveMember) {
      dataSearchMember.group_chat_id = this.groupItemSelected;
    }
    this.isLoadingSearchUser = true;
    await GroupChatService.getMemberByGroupId(dataSearchMember)
      .then((res: any) => {
        if (res.status === 200) {
          this.lstMemberSearch = res.data.data.map((item: any) => {
            return {
              userId: item.id,
              userName: item.nickname,
              choose: false,
            };
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    this.isLoadingSearchUser = false;
    this.showMsgSearchUserChat = true;
  }

  async createGroupChat() {
    this.disableCreate = true;
    // check lst member choose
    if (this.lstMemberChoose.length > 0) {
      let lstMemberIdChoose: any[] = [];
      // push id log in to group chat
      // get all lst member id choose for group chat
      this.lstMemberChoose.forEach((user: any) => {
        if (!lstMemberIdChoose.includes(user.userId)) {
          lstMemberIdChoose.push(user.userId);
        }
      });

      if (this.isInviteMember) {
        // update member in group chat
        let inviteData = {
          group_chat_id: this.groupItemSelected,
          user_ids: lstMemberIdChoose,
        };

        GroupChatService.inviteMemberToGroupChat(inviteData)
          .then((res: any) => {
            if (res.status === 200) {
              this.getInfoGroupChat();
              this.$bvModal.hide("modal-manage-member");
              this.$bvModal.hide("modal-add-member");

              //update fire base group chat
              // update last msg group chat
              groupchatRef
                .where("groupChatId", "==", this.groupItemSelected)
                .get()
                .then((querySnapshot: any) => {
                  let groupChatUpdate: any = {};
                  querySnapshot.forEach((snapshot: any) => {
                    groupChatUpdate.id = snapshot.id;
                    groupChatUpdate.groupChatId = snapshot.data().groupChatId;
                    groupChatUpdate.userIds = snapshot.data().userIds;
                  });

                  // update in firebase
                  if (groupChatUpdate.id) {
                    groupchatRef.doc(groupChatUpdate.id).update({
                      userIds: groupChatUpdate.userIds.concat(
                        inviteData.user_ids
                      ),
                    });
                  }
                });
            }
          })
          .catch((err: any) => {
            console.log(err);
          })
          .finally(() => (this.disableCreate = false));
      } else {
        // check group chat exist or create new group chat
        // set user id
        let userId = "";
        if (lstMemberIdChoose.length > 0) {
          lstMemberIdChoose.forEach((item: any) => {
            userId += `${item},`;
          });
          userId = userId.slice(0, -1);
        }

        // If add 1 user ? check group exist : create new group
        if (lstMemberIdChoose.length === 1) {
          await GroupChatService.checkChatPlan(lstMemberIdChoose.toString())
            .then(async (response) => {
              if (response.status === 200 && response.data.is_check_plan) {
                await GroupChatService.getGroupChatExist(
                  lstMemberIdChoose.toString()
                )
                  .then(async (res) => {
                    if (res.status === 200) {
                      this.$bvModal.hide("modal-manage-member");
                      this.$bvModal.hide("modal-add-member");
                      if (res.data.is_existed) {
                        let group_chat_id = res.data.group_chat.id;
                        await GroupChatService.revertOriginMember(
                          group_chat_id
                        );
                        ChatModule.TOGGLE_IS_CREATED_NEW_GROUP_CHAT();
                        this.$router.push({
                          name: "chat-detail",
                          params: { groupChatId: group_chat_id },
                        });
                      } else {
                        this.createNewGroupChat(lstMemberIdChoose);
                      }
                    }
                  })
                  .catch((err) => console.log(err))
                  .finally(() => (this.disableCreate = false));
              } else {
                this.$bvModal.show(
                  "modal-show-err"
                  // "create-group-chat-fail-modal-by-check-plan"
                );
              }
            })
            .catch((err) => console.log(err));
        } else if (lstMemberIdChoose.length >= 2) {
          this.createNewGroupChat(lstMemberIdChoose);
        }
      }
    }
  }

  private createNewGroupChat(listMemberChoose: any) {
    this.$bvModal.show("create-group-chat-error-modal");
    let groupChatData = {
      user_ids: listMemberChoose.toString(),
      sender_id: this.$store.state.userInfo.user.profile.user_id,
      last_message: "",
      size: 0,
      last_message_special: false,
      only_user: listMemberChoose.length > 1 ? false : true,
      name: this.handleChatTitle(),
      group_id: this.selectedGroup.id,
    };

    this.$blockui.show();
    GroupChatService.createNewGroupChat(groupChatData)
      .then((res) => {
        if (res.status === 201) {
          this.$bvModal.hide("modal-manage-member");
          this.$bvModal.hide("modal-add-member");
          this.$router.push({
            name: "chat-detail",
            params: { groupChatId: `${res.data.id}` },
          });
        }
      })
      .catch((err) => {
        if (err.response.data.message === "USER_NO_EXIST_GENERAL_GROUP_TAG") {
          this.$bvModal.show("create-group-chat-fail-modal");
        }
      })
      .finally(() => {
        this.$blockui.hide();
        this.disableCreate = false;
      });
  }

  chooseMember(user: any) {
    if (user.disable) return;
    const userId = user.userId;
    this.lstMemberSearch.forEach((user: any) => {
      if (user.userId === userId) user.choose = !user.choose;
      else user.choose = false;
    });
  }

  chooseMemberRemove(user: any) {
    if (user.disable) return;
    const userId = user.userId;
    this.lstMemberChoose.forEach((user: any) => {
      if (user.userId === userId) user.choose = !user.choose;
      else user.choose = true;
    });
  }

  addMemberToLstMemberChoose() {
    let lstMemberChooseTemp = [
      ...this.lstMemberSearch.filter((user: any) => user.choose),
    ];
    if (lstMemberChooseTemp.length) {
      let lstMemberSearchTemp = this.lstMemberChoose.map((item: any) => {
        return {
          userId: item.userId,
          userName: item.userName,
          choose: false,
        };
      });

      // change lst member choose from lst member search to lst member choose
      this.lstMemberChoose = [
        ...this.lstMemberSearch.filter((user: any) => user.choose),
      ];

      // sort lst member choose by user name
      // this.list_member_choose = this.list_member_choose.sort(
      //   (a: any, b: any) => {
      //     if (a.userName < b.userName) {
      //       return -1
      //     } else {
      //       return 0
      //     }
      //   }
      // )
      // update lst member search
      this.lstMemberSearch = [
        ...lstMemberSearchTemp,
        ...this.lstMemberSearch.filter((user: any) => !user.choose),
      ];

      if (this.lstMemberChoose.length) {
        this.lstMemberSearch = this.lstMemberSearch.filter(
          (item: any) => item.userId != this.lstMemberChoose[0].userId
        );
      }
    }
  }

  removeMemberFromLstMemberChoose() {
    // update lst member search
    this.lstMemberSearch = [
      ...this.lstMemberSearch,
      ...this.lstMemberChoose.filter((user: any) => !user.choose),
    ];

    // sort lst member choose by user name
    this.lstMemberSearch = this.lstMemberSearch.sort((a: any, b: any) => {
      if (a.userName < b.userName) {
        return -1;
      } else {
        return 0;
      }
    });

    // update lst member choose
    this.lstMemberChoose = this.lstMemberChoose.filter(
      (user: any) => user.choose
    );
  }

  /**
   * Handle chat title
   */
  handleChatTitle() {
    const names = this.lstMemberChoose.map((item) => item.userName);
    if (names.length > 2) {
      return (
        names.join(`${this.$t("common.suffix.san")}${this.$t("chat.comma")}`) +
        this.$t("chat.chat_group_name_with", {
          other_user_count: names.length - 2,
        })
      );
    } else {
      return (
        names
          .map((name) => `${name}${this.$t("common.suffix.san")}`)
          .join(`${this.$t("chat.comma")}`) + this.$t("chat.chat_with")
      );
    }
  }
}
