export class PickMenuItem {
  /**
   * @Unique
   * ID
   */
  id!: string

  /**
   * Name to display on pick list
   */
  name!: string

  /**
   * @Optional
   * Use if want to custom display name
   * 
   * write HTML as string
   */
  nameHtml?: string

  /**
   * @Optional
   * Use if this is not collapse
   * 
   * Link to of router link
   */
  link?: string

  /**
 * @Optional
 * Use if this is not collapse
 *
 * Function of this row
 */
  action?: (param?: any) => any

  /**
   * @Optional
   * Use if this is collapse
   * 
   * children's of this item
   */
  childs?: PickMenuItemChild[]

  /**
 * @Optional
 * Use if this is collapse
 * 
 * This collapse will always visible
 */
  alwaysCollapseVisible?: boolean

  /**
   * @Optional
   * Use if this is collapse
   * 
   * At start, is this collapse visible
   */
  collapseVisible?: boolean

  /**
   * @Optional
   * Use if this is the last item
   * Use if this is not collapse
   * 
   * This item will sticky to the bottom
   */
  lastItemSticky?: boolean //define for lastest item only

  constructor(init?: Partial<PickMenuItem>) {
    if (init?.alwaysCollapseVisible) {
      init.collapseVisible = true
    }
    if (init?.childs && init?.childs?.length > 0) {
      init.link = undefined
    }
    Object.assign(this, init)
  }
}

export class PickMenuItemChild {
  /**
   * @Unique
   * ID
   */
  id!: string

  /**
   * Name to display on pick list
   */
  name!: string

  /**
   * Link to of router link
   */
  link!: string

  /**
* @Optional
* Use if this is not collapse
*
* Function of this row
*/
  action?: (param?: any) => any

  /**
  * @Optional
  * Use if want to custom display name
  * 
  * write HTML as string
  */
  nameHtml?: string

  constructor(init?: Partial<PickMenuItemChild>) {
    Object.assign(this, init)
  }
}