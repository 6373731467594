
































import { Component, Vue } from 'vue-property-decorator'
import { ISettingMenu } from '@/models/Setting/Interface'

@Component
export default class SettingMenu extends Vue {
  private list_setting_menu: ISettingMenu[] = []

  created() {
    this.getSettingMenu()
  }

  getSettingMenu() {
    let data: ISettingMenu[] = [
      {
        id: String(100 * Math.random()),
        link: '/setting/account-info',
        name: this.$t('setting.setting_menu.account_info'),
        status_company: String(),
      },
      {
        id: String(100 * Math.random()),
        link: '/setting/setting-language',
        name: this.$t('setting.setting_menu.setting_language'),
        status_company: String(),
      },
      {
        id: String(100 * Math.random()),
        link: '/setting/setting-order',
        name: this.$t('setting.setting_menu.display_order_groups'),
        status_company: String(),
      },
      {
        id: String(100 * Math.random()),
        link: '/setting/setting-display',
        name: this.$t('setting.setting_menu.setting_display'),
        status_company: String(),
      },
      {
        id: String(100 * Math.random()),
        link: '/setting/setting-notify-news',
        name: this.$t('setting.setting_menu.setting_notify_news'),
        status_company: String(),
      },
      // {
      //   id: String(100 * Math.random()),
      //   link: '/setting/setting-theme',
      //   name: this.$t('setting.setting_menu.setting_theme')
      // },
      {
        id: String(100 * Math.random()),
        link: '/setting/change-info',
        name: this.$t('setting.setting_menu.change_profile'),
        status_company: String(),
      }
    ]
    this.list_setting_menu = data
  }
}
