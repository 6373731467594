import { EUnreadType } from '.';
import { EUserStatus, keepFirstElementOnly } from '..';
//@ts-ignore
import _ from 'lodash'
import { UserLeaveGroupChat } from '../Chat';
/**
 * @param id: number
 * @param is_readed: boolean
 * @param unread_icon: string[]
 * @param unread_target_id: number
 * @param unread_type: number
 * @param update_by_user: string[]
 * @param update_date: string
 */
export interface IUnreadNotification {
  id: number
  allow_anonymous_comment: boolean
  common_topic_name: string
  emergency_contact: boolean
  group_id: number
  group_name: string
  title: string
  name: string //for chat
  only_user_chat: boolean //for chat
  topic_comment_id: number //for topic
  content: string
  type: number
  unread_icon: string[]
  unread_target_id: number
  unread_type: EUnreadType
  update_by_user: string
  updated_at: string
  selected: boolean
  user_active_status: EUserStatus
  user_leave_chat: UserLeaveGroupChat
}

export class UnreadNoti {
  id!: number
  allow_anonymous_comment: boolean = true
  common_topic_name: string = ''
  emergency_contact: boolean = false
  group_id!: number
  group_name: string = ''
  title: string = ''
  name: string = '' //for chat
  only_user_chat: boolean = false //for chat
  content: string = ''
  type!: number
  unread_icon: string[] = []
  unread_target_id!: number
  unread_type!: EUnreadType
  update_by_user: string = ''
  updated_at: string = ''
  selected: boolean = false
  user_active_status!: EUserStatus

  constructor(init?: Partial<UnreadNoti>) {
    Object.assign(this, init)
    if (this.content) {
      this.content = '<div><span>' + this.content + '</span></div>'
      let doc = new DOMParser().parseFromString(this.content, 'text/html')
      let wrapper: any = doc.querySelector('div')
      let el: any = wrapper.firstChild
      if (el) {
        el.innerText = _.truncate(el.innerText, {
          'length': 50,
          'separator': ' '
        });
        el?.classList ? el?.classList.add("text-line-clamp-1") : ''
        this.content = keepFirstElementOnly(wrapper, el, wrapper.childElementCount > 1)
      }
    }
  }
}

export interface IBellNotification {
  id: number
  user_avatar: string
  message: string
  is_unread: boolean
  time_check: string
}

export interface ILikeNotification {
  id: number
  like_by_user: string[]
  like_count: number
  like_icon: string
  like_type: number
  like_target: string
  like_target_id: number
  group_id: number
}

/**
 * @param notifications: number[]
 */
export interface NotificationSelected {
  notifications: number[]
}