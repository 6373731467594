import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'auth' })
class Auth extends VuexModule {
  token?: string | null
  loginFromLoginByEmail: boolean = false
  redirectLoginWithLine: string = ''

  @Mutation
  setNewToken(newToken: string) {
    this.token = newToken
  }

  @Mutation
  setNewStatusLoginFromLoginByEmail(newStatus: boolean) {
    this.loginFromLoginByEmail = newStatus
  }

  @Mutation
  setTokenNull() {
    this.token = null
  }

  @Mutation
  setRedirectLoginInWithLine(url: string) {
    this.redirectLoginWithLine = url
  }

  @Action
  SET_NEW_TOKEN(newToken: string) {
    this.context.commit('setNewToken', newToken)
  }

  @Action
  SET_TOKEN_NULL() {
    this.context.commit('setTokenNull')
  }

  @Action
  SET_NEW_STATUS_LOGIN_FROM_LOGIN_BY_EMAIL(newStatus: boolean) {
    this.context.commit('setNewStatusLoginFromLoginByEmail', newStatus)
  }

  @Action
  SET_REDIRECT_LOGIN_WITH_LINE(url: string) {
    this.context.commit('setRedirectLoginInWithLine', url)
  }
}

export default Auth