import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import store from '@/store';
import { DeleteFileParams, FileGetParams, FileHistoryGetParams, RevertFileHistoryParams, UpdateInformationParams } from '@/models';

class CommonSharedFolderService {

  getAllFileCommonTopic(params: FileGetParams) {
    return axiosApiInstance.get(`${Const.api_url}common-share-folder?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}&unread_only=${params.unread_only}`)
  }

  getFileCommonTopic(fileId: string) {
    return axiosApiInstance.get(`${Const.api_url}common-share-folder-file/${fileId}`)
  }

  getFileCommonTopicHistory(params: FileHistoryGetParams) {
    return axiosApiInstance.get(`${Const.api_url}common-share-folder-history-file/${params.file_id}?limit=${params.limit}&page=${params.page}`)
  }

  isCommonFileFavorite(fileId: string) {
    return axiosApiInstance.get(`${Const.api_url}is-favorite-common-file/${fileId}`)
  }

  markCommonFileAsRead(fileId: number) {
    return axiosApiInstance.post(`${Const.api_url}mark-read-common-file/${fileId}`)
  }

  markCommonFileAsFavorite(fileId: string) {
    return axiosApiInstance.post(`${Const.api_url}mark-favorite-common-file/${fileId}`)
  }

  overwriteCommonFile(params: FormData) {
    return axiosApiInstance.post(`${Const.api_url}overwrite-common-file/${params.get('fileId')}`, params)
  }

  updateCommonFileInformation(params: UpdateInformationParams) {
    return axiosApiInstance.post(`${Const.api_url}update-info-common-file/${params.file_id}`, params)
  }

  deleteCommonFile(params: DeleteFileParams) {
    return axiosApiInstance.post(`${Const.api_url}delete-common-file`, params)
  }

  revertCommonFileHistory(params: RevertFileHistoryParams) {
    return axiosApiInstance.post(`${Const.api_url}revert-common-file/${params.file_id}/from-history/${params.file_history_id}`)
  }

  deleteCommonHistory(historyId: number, fileId: string) {
    return axiosApiInstance.post(`${Const.api_url}delete-common-file-history/${historyId}`, { file_id: fileId })
  }

  downloadCommonFiles(fileIds: number[]) {
    return axiosApiInstance.post(`${Const.api_url}download-files`, { file_ids: fileIds, is_comment_file: false }, { responseType: 'arraybuffer' })
  }
}

export default new CommonSharedFolderService
