import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { NotificationSelected } from '@/models/Notification'

class NotificationService {
  getNotificationCount(notification_type: number) {
    return axiosApiInstance.get(Const.api_url + 'members/me/notification/count/' + notification_type)
  }

  getNotificationCountByGroup(groupId: number) {
    return axiosApiInstance.get(`${Const.api_url}members/me/notification/${groupId}/count`)
  }

  getNotificationUnread(limit: number, page: number, group_id?: number) {
    if (!group_id) {
      return axiosApiInstance.get(Const.api_url + 'members/me/notification/unread?limit=' + limit + '&page=' + page)
    } else {
      return axiosApiInstance.get(Const.api_url + 'members/me/notification/unread?limit=' + limit + '&page=' + page + '&group_id=' + group_id)
    }
  }

  getNotificationLike(limit: number, page: number) {
    return axiosApiInstance.get(Const.api_url + 'members/me/notification/like?limit=' + limit + '&page=' + page)
  }

  getNotificationFavorite(limit: number, page: number) {
    return axiosApiInstance.get(`${Const.api_url}members/me/favorite?limit=${limit}&page=${page}`)
  }

  getFavoriteCount() {
    return axiosApiInstance.get(`${Const.api_url}members/me/count-favorite`)
  }

  markReadAllLike() {
    return axiosApiInstance.get(`${Const.api_url}members/me/notification/mark-read-all-like`)
  }

  markReadedNotification(notifications: NotificationSelected) {
    return axiosApiInstance.post(Const.api_url + 'members/me/notification/set-notification-is-seen', notifications)
  }
}

export default new NotificationService
