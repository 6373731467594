import { EFileFormat } from '@/models'
class CheckFileFormat {

  getFileFormatWithFileName(fileName: string): EFileFormat {
    const fileNameSplit: string[] = fileName.split('.')
    const extention = fileNameSplit[fileNameSplit.length - 1]
    return this.getFileFormat(extention)
  }

  getIconClassNameWithFileName(fileName: string) {
    const fileNameSplit: string[] = fileName.split('.')
    const extention = fileNameSplit[fileNameSplit.length - 1]
    const format = this.getFileFormat(extention)
    return this.getIconClassName(format)
  }

  getIconClassNameWithFileExtention(fileExtention: string) {
    const format = this.getFileFormat(fileExtention)
    return this.getIconClassName(format)
  }

  getFileFormat(extension: string): EFileFormat {
    if (!extension) return EFileFormat.OTHER
    const fileExtension = extension?.toLowerCase()
    if ('jpg,jpeg,png,gif'.includes(fileExtension)) return EFileFormat.IMAGE
    if ('mp4,mov,wmv,avi'.includes(fileExtension)) return EFileFormat.VIDEO
    if ('zip'.includes(fileExtension)) return EFileFormat.ZIP
    if ('xlsx,xls'.includes(fileExtension)) return EFileFormat.EXCEL
    if ('csv'.includes(fileExtension)) return EFileFormat.CSV
    if ('pdf'.includes(fileExtension)) return EFileFormat.PDF
    return EFileFormat.OTHER
  }

  getIconClassName(format: EFileFormat) {
    switch (format) {
      case EFileFormat.IMAGE:
        return 'far fa-file-image color-icon-file'
      case EFileFormat.VIDEO:
        return 'fas fa-film-alt color-icon-file'
      case EFileFormat.ZIP:
        return 'fas fa-file-archive color-icon-file'
      case EFileFormat.EXCEL:
        return 'fas fa-file-spreadsheet color-icon-file'
      case EFileFormat.CSV:
        return 'fas fa-file-csv color-icon-file'
      case EFileFormat.PDF:
        return 'fas fa-file-alt color-icon-file'
      case EFileFormat.OTHER:
        return 'fas fa-file color-icon-file'
      default:
        return ''
    }
  }
}

export default new CheckFileFormat()