
















import { Prop, Component, Vue } from 'vue-property-decorator'

@Component
export default class ModalConfirm extends Vue {
  @Prop({ default: 'modal-confirm' }) private id!: string
  @Prop() confirmMess!: string

  no() {
    this.$bvModal.hide(this.id)
    setTimeout(() => this.$emit('no'), 300)
  }

  yes() {
    this.$bvModal.hide(this.id)
    setTimeout(() => this.$emit('yes'), 300)
  }
}
