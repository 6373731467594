import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'chat' })
class Chat extends VuexModule {
  isCreatedNewGroupChat: boolean = false

  @Mutation
  toggleIsCreatedNewGroupChat() {
    this.isCreatedNewGroupChat = !this.isCreatedNewGroupChat
  }

  @Action
  TOGGLE_IS_CREATED_NEW_GROUP_CHAT() {
    this.context.commit('toggleIsCreatedNewGroupChat')
  }
}

export default Chat