export enum EThemeType {
  NO_THEME,
  CUSTOM_THEME,
  PRESCRIPTIVE_THEME
}

export enum EhumbNailsDisplay {
  HIDE_THUMBNAILS,
  DISPLAY_THUMBNAILS
}

export enum ETopageSort {
  SORT_BY_NEW,
  SORT_BY_CLS
}

export enum EListSort {
  UPDATE_DESC,
  UPDATE_ASC,
  TITLE_DESC,
  TITLE_ASC,
}

export enum NameSearchCompany {
  LOCATION = 'location',
  INDUSTRY = 'industry',
  DEI = 'dei',
  PERK = 'perk'
}
