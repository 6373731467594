




















































import { Component, Vue } from 'vue-property-decorator'
import AuthService from '@/services/AuthService'
import i18n from '@/lang'

import store from '@/store'
import { getModule } from 'vuex-module-decorators'

import Auth from '@/store/modules/Auth'
import Setting from '@/store/modules/Setting'

const AuthModule = getModule(Auth, store)
const SettingModule = getModule(Setting, store)

@Component
export default class NavMenuBar extends Vue {
  private privacy_policy_url = process.env.VUE_APP_PRIVACY_POLICY_URL

  created() {
    this.changeViewPoint()
  }

  togglePcMobileLayout() {
    const viewPointDesktop = this.$store.state.setting.viewPointDesktop
    SettingModule.TOGGLE_VIEW_POINT(!viewPointDesktop)
    this.changeViewPoint()
  }

  changeViewPoint() {
    const viewPointDesktop = this.$store.state.setting.viewPointDesktop
    const viewport: any = document.querySelector('meta[name=viewport]')
    if (viewPointDesktop) {
      viewport.setAttribute(
        'content',
        'width=1200, initial-scale=0, maximum-scale=1.0, minimum-scale=0.25, user-scalable=yes'
      )
    } else {
      viewport.setAttribute(
        'content',
        'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no'
      )
    }
  }

  async logout() {
    await AuthService.logout()
      .then(res => {
        if (res.status === 200) {
          AuthModule.SET_TOKEN_NULL()
          i18n.locale = 'ja'
          this.$router.push({
            name: this.$store.state.auth.loginFromLoginByEmail
              ? 'email-login'
              : 'login'
          })
        }
      })
      .catch(err => {
        if (err) {
          this.$router.push({ name: 'home' })
        }
      })
    sessionStorage.clear()
    localStorage.clear()
  }
}
