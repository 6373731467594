var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{ref:"navHeader",staticClass:"bg-header-primary"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center px-3 px-xl-0"},[_c('router-link',{staticClass:"h-100",attrs:{"to":"/"}},[_c('img',{staticClass:"h--6 h-xl--7 d-flex align-items-center",attrs:{"src":require("@/assets/images/logo_bridges-admin_20231025_WH_ol.svg"),"alt":"logo"}})]),_c('ul',{staticClass:"list-unstyled d-flex align-items-center mb-0 py-1 position-relative"},[_c('li',[_c('ul',{staticClass:"list-unstyled d-flex align-items-center me-3 me-xl-6"},[_c('li',[_c('b-dropdown',{ref:"dropdownUnread",staticClass:"btn-dropdown-notice",attrs:{"left":"","menu-class":"dropdown-notification unread button-border-radius","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"position-relative h-100 flex-center",on:{"click":_vm.handleToggleUnread}},[_c('i',{staticClass:"fas fa-bell fs-14 ps-xl-2 text-white"}),(_vm.unread_notifiction_count > 0)?_c('span',{staticClass:"notice-count d-xl-none fs-14"},[_vm._v(" "+_vm._s(_vm.unread_notifiction_count > 100 ? '+99' : _vm.unread_notifiction_count)+" ")]):_vm._e()]),_c('span',{staticClass:"d-none d-xl-flex fs-12 fwb px-2 h-100 flex-center py-1 text-white",on:{"click":_vm.handleToggleUnread}},[_c('span',{staticClass:"fs-xl-12 text-white"},[_vm._v(_vm._s(_vm.$t('nav_header.not_seen')))]),_c('span',{staticClass:"px-1 fs-xl-12",class:{
                        'color-unread': _vm.unread_notifiction_count > 0
                      }},[_vm._v(" "+_vm._s(_vm.unread_notifiction_count > 100 ? '+99' : _vm.unread_notifiction_count)+" ")]),_c('span',{staticClass:"fs-xl-12 text-white"},[_vm._v(_vm._s(_vm.$t('nav_header.item')))])])]},proxy:true}])},[_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('button',{staticClass:"btn px-2 button-border-radius",on:{"click":_vm.hideDropdownUnread}},[_c('i',{staticClass:"far fa-times fs-14 text-dark-tint cursor-pointer"})])]),_c('UnreadNotification',{attrs:{"unreadFrom":_vm.eFrom.NAVBAR,"selectMulti":false,"toggleUnread":_vm.toggleUnread,"triggerUnread":_vm.triggerUnread},on:{"hideDropdownUnread":_vm.hideDropdownUnread}}),_c('div',{staticClass:"text-center py-4"},[_c('router-link',{attrs:{"to":"/notification/unread"},nativeOn:{"click":function($event){return _vm.hideDropdownUnread.apply(null, arguments)}}},[_c('button',{staticClass:"btn btn-outline-dark-tint-less button-border-radius"},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")])])],1)],1)])],1),_c('li',[_c('b-dropdown',{ref:"dropdownLike",staticClass:"btn-dropdown-notice",attrs:{"menu-class":"dropdown-notification like button-border-radius","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"position-relative h-100 flex-center",on:{"click":_vm.handleToggleLike}},[_c('i',{staticClass:"fas fa-thumbs-up fs-xl-14 ps-xl-2 text-white"}),(_vm.like_notifiction_count > 0)?_c('span',{staticClass:"notice-count d-xl-none"},[_vm._v(" "+_vm._s(_vm.like_notifiction_count > 100 ? '+99' : _vm.like_notifiction_count)+" ")]):_vm._e()]),_c('span',{staticClass:"d-none d-xl-flex fs-12 fwb px-2 h-100 flex-center py-1 text-white",on:{"click":_vm.handleToggleLike}},[_c('span',{staticClass:"fs-xl-12 text-white"},[_vm._v(_vm._s(_vm.$t('nav_header.like')))]),_c('span',{staticClass:"px-1 fs-xl-12",class:{
                        'color-unread': _vm.like_notifiction_count > 0
                      }},[_vm._v(" "+_vm._s(_vm.like_notifiction_count > 100 ? '+99' : _vm.like_notifiction_count)+" ")]),_c('span',{staticClass:"fs-xl-12 text-white"},[_vm._v(_vm._s(_vm.$t('nav_header.item')))])])]},proxy:true}])},[_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('button',{staticClass:"btn px-2 button-border-radius",on:{"click":_vm.hideDropdownLike}},[_c('i',{staticClass:"far fa-times fs-24 text-dark-tint cursor-pointer"})])]),_c('LikeNotification',{attrs:{"toggleLike":_vm.toggleLike,"triggerLike":_vm.triggerLike},on:{"hideDropdownLike":_vm.hideDropdownLike}}),_c('div',{staticClass:"text-center py-4"},[_c('router-link',{attrs:{"to":"/notification/like"},nativeOn:{"click":function($event){return _vm.hideDropdownLike.apply(null, arguments)}}},[_c('button',{staticClass:"btn btn-outline-dark-tint-less button-border-radius"},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")])])],1)],1)])],1),_c('li',[_c('b-dropdown',{ref:"dropdownFavourite",staticClass:"btn-dropdown-notice",attrs:{"menu-class":"dropdown-notification favorite button-border-radius","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"position-relative h-100 flex-center",on:{"click":_vm.handleToggleFavorite}},[_c('i',{staticClass:"fas fa-heart fs-14 ps-xl-2 text-white"}),(_vm.favourite_notifiction_count > 0)?_c('span',{staticClass:"notice-count d-xl-none fs-xl-14"},[_vm._v(" "+_vm._s(_vm.favourite_notifiction_count > 100 ? '+99' : _vm.favourite_notifiction_count)+" ")]):_vm._e()]),_c('span',{staticClass:"d-none d-xl-flex fs-14 fwb px-2 h-100 flex-center py-1 text-white",on:{"click":_vm.handleToggleFavorite}},[_c('span',{staticClass:"fs-xl-12 text-white"},[_vm._v(_vm._s(_vm.$t('nav_header.favorite')))]),_c('span',{staticClass:"px-1 fs-xl-12",class:{
                        'color-unread': _vm.favourite_notifiction_count > 0
                      }},[_vm._v(" "+_vm._s(_vm.favourite_notifiction_count > 100 ? '+99' : _vm.favourite_notifiction_count)+" ")]),_c('span',{staticClass:"fs-xl-12 text-white"},[_vm._v(_vm._s(_vm.$t('nav_header.item')))])])]},proxy:true}])},[_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('button',{staticClass:"btn px-2 button-border-radius",on:{"click":_vm.hideDropdownFavourite}},[_c('i',{staticClass:"far fa-times fs-14 text-dark-tint cursor-pointer"})])]),_c('FavouriteNotification',{attrs:{"favoriteFrom":_vm.eFrom.NAVBAR,"toggleFavorite":_vm.toggleFavorite,"triggerFavorite":_vm.triggerFavorite},on:{"hideDropdownFavourite":_vm.hideDropdownFavourite}}),_c('div',{staticClass:"text-center py-4"},[_c('router-link',{attrs:{"to":"/notification/favourite"},nativeOn:{"click":function($event){return _vm.hideDropdownFavourite.apply(null, arguments)}}},[_c('button',{staticClass:"btn btn-outline-dark-tint-less button-border-radius"},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")])])],1)],1)])],1)])]),_c('li',{staticClass:"me-3 me-xl-8 d-xl-none"},[_c('div',{staticClass:"cursor-pointer outline-0",on:{"click":_vm.openModalSearch}},[_c('i',{staticClass:"fal fa-search fs-14"})])]),_c('li',{ref:"profile"},[_c('router-link',{staticClass:"d-flex align-items-center",attrs:{"to":"/setting/change-info"}},[_c('figure',{staticClass:"mb-0 me-2",staticStyle:{"width":"35px","height":"35px"}},[(this.$store.state.userInfo.user.profile.avatar_small)?_c('img',{staticClass:"img-cover rounded-circle",attrs:{"src":this.$store.state.userInfo.user.profile.avatar_small,"alt":"user-avatar"}}):_c('i',{staticClass:"fas fa-user-circle img-cover rounded-circle fs-32 ps-xl-2 text-white"})]),(!_vm.$store.state.userInfo.user.profile.is_admin_company)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                customClass: _vm.handleLongText().isLong ? 'd-block' : 'd-none'
              }),expression:"{\n                customClass: handleLongText().isLong ? 'd-block' : 'd-none'\n              }",modifiers:{"hover":true}}],staticClass:"d-none d-xl-block fs-14 fwb text-white",attrs:{"title":_vm.handleLongText().name}},[_vm._v(" "+_vm._s(_vm.handleLongText().text))]):(_vm.$store.state.userInfo.user.profile.status_company == 'stop')?_c('span',{staticClass:"d-none d-xl-block fs-14 fwb text-white"},[_vm._v(" 停止された企業様 ")]):(_vm.$store.state.userInfo.user.profile.status_company == 'leave')?_c('span',{staticClass:"d-none d-xl-block fs-14 fwb text-white"},[_vm._v(" 退会された企業様 ")]):_c('span',{staticClass:"d-none d-xl-block fs-14 fwb text-white"},[_vm._v(" "+_vm._s(_vm.$store.state.userInfo.user.profile.name + '@' + _vm.$store.state.userInfo.user.profile.name_admin_company)+" ")])])],1)])],1)]),_c('ModalSearchHeader',{attrs:{"id":_vm.modalSearchHeaderId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }