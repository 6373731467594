export enum ENameColor {
  WHITE = 1,
  BLACK = 2
}

export enum EFeedCls {
  FILE = 0,
  FOLDER = 1
}

export enum EInviteAuth {
  EVERYONE = 0,
  ADMIN = 1
}

export enum EEditMode {
  TEXT = 0,
  HTML = 1
}

export enum EAvatarType {
  FILE_UPLOAD = 1,
  DEFAULT = 2,
  NONE = 3
}

export enum ETopicType {
  COMMON_TOPIC = 1,
  GROUP_TOPIC = 2,
}

export enum EMemberRole {
  ADMIN = 0,
  MEMBER = 1
}

export enum EInviteBy {
  LINK,
  MAIL
}

export enum EFileFormat {
  IMAGE,
  VIDEO,
  ZIP,
  EXCEL,
  CSV,
  PDF,
  OTHER
}

export enum EGroupRegisterType {
  ADMIN_REGISTER,
  USER_REGISTER_INVITE,
  USER_REGISTER_REQUEST_JOIN,
  USER_TEPM_REGISTER
}

export enum EFolderType {
  SHARED_FOLDER = 1,
  TOPIC = 2
}

export enum EFileBelong {
  SHARED_FOLDER = 1,
  TOPIC = 2
}

export enum MoveToUnclsFrom {
  BIN,
  FOLDER
}

export enum EGroupAuth {
  ADMIN = 1,
  INVITE = 2,
  EDIT_SHARED_FOLDER = 3,
  EDIT_TOPIC = 4,
}

export enum EDeleteFileType {
  MOVE_TO_BIN = 1,
  HARD_DELETE = 2
}

export enum EFileError {
  NONE,
  EMPTY,
  OVER_SIZE,
  NOT_IMAGE
}

export enum EFolderItemCountType {
  ALL,
  UNCLASSIFIED,
  IN_BIN,
  FOLDER,
  ATTACH_FILE,
  DRAFT
}

export enum EUserStatus {
  NORMAL,
  LEAVE_GROUP_TAG,
  LEAVE_SYSTEM
}
