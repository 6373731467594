







import { Component, Vue } from 'vue-property-decorator'
import NavHeader from '@/components/NavHeader.vue'
import NavMenu from '@/components/NavMenu.vue'

@Component({
  components: {
    NavHeader,
    NavMenu
  }
})
export default class HeaderCompact extends Vue {}
