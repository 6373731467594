








import { Component, Vue } from 'vue-property-decorator'
import PickMenu from '@/components/PickMenu/index.vue'
import { settingData } from '@/views/Setting/data'
import { ISettingMenu } from '@/models/Setting/Interface'
import { PickMenuItem } from '@/models/Common'

@Component({ components: { PickMenu } })
export default class PickSettingMenu extends Vue {
  private settings: PickMenuItem[] = []

  created() {
    this.settings = [
      new PickMenuItem({
        id: 'settingMenu',
        name: this.$t('common.menu.setting') as any,
        alwaysCollapseVisible: true,
        childs: settingData.map((setting_menu: ISettingMenu) => {
          return {
            id: setting_menu.id,
            name: setting_menu.name,
            link: setting_menu.link
          }
        })
      })
    ]
  }
}
