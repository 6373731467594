import { EUserStatus } from '..'

export class MessageModel {
  messageId!: number
  content: string = ''
  senderId!: number
  groupChatId!: number
  userName: string = ''
  userAvatar: string = ''
  userStatus!: EUserStatus
  timeSent?: Date // format YYYY/MM/DD HH:MM
  // data convert
  day?: string
  time?: string
  dayWeek?: string
  isCanDelete: boolean = false
  liker: any[] = []
  isLike: boolean = false
  index: number = 0
  messageSpecial: boolean = false
  isOwnMsg: boolean = false
  replyMessage: MessageModel | null = null
  file: any = []
  is_html_content: boolean = false
  constructor(init?: Partial<MessageModel>) {
    Object.assign(this, init)
  }
}
