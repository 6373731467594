class CalendarColor {

  public black = '#000000'

  public white = '#FFFFFF'
  public bananaGreen = '#CBFD04'
  public pink = '#F9CCFF'
  public blue = '#66FFFF'
  public green = '#059A27'
  public gray = '#B9B4B4'
  public brown = '#996633'
  public red = '#FF0000'

  public whiteCalendar: ICalendarColor = { bg_color: this.white, text_color: this.black }
  public bananaGreenCalendar: ICalendarColor = { bg_color: this.bananaGreen, text_color: this.black }
  public pinkCalendar: ICalendarColor = { bg_color: this.pink, text_color: this.black }
  public blueCalendar: ICalendarColor = { bg_color: this.blue, text_color: this.black }
  public greenCalendar: ICalendarColor = { bg_color: this.green, text_color: this.white }
  public grayCalendar: ICalendarColor = { bg_color: this.gray, text_color: this.white }
  public brownCalendar: ICalendarColor = { bg_color: this.brown, text_color: this.white }
  public redCalendar: ICalendarColor = { bg_color: this.red, text_color: this.white }

  getAllColor() {
    return [
      this.white,
      this.bananaGreen,
      this.pink,
      this.blue,
      this.green,
      this.gray,
      this.brown,
      this.red
    ]
  }

  getAllColorCalendar() {
    return [
      this.whiteCalendar,
      this.bananaGreenCalendar,
      this.pinkCalendar,
      this.blueCalendar,
      this.greenCalendar,
      this.grayCalendar,
      this.brownCalendar,
      this.redCalendar
    ]
  }

  getTextColor(bgColor: string) {
    const color = this.getAllColorCalendar().filter(item => item.bg_color === bgColor)
    if (color[0]) {
      return color[0].text_color
    }
    return this.black
  }
}

export default CalendarColor

export interface ICalendarColor {
  bg_color: string,
  text_color: string
}
